import React, { useState } from "react";
import ReactDOM from "react-dom";
import Icon from "./Icon";

// Define the props for TooltipModal
interface TooltipModalProps {
  content: string; // Tooltip content must be a string
  strokeColor?:string;
  className?:string;
}

const TooltipModal: React.FC<TooltipModalProps> = ({ content,strokeColor,className = "" }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleInteraction = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>|React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      x: rect.right + 10,
      y: rect.top + window.scrollY, 
    });
    setShowTooltip(!showTooltip);
  };

  const closeTooltip = () => setShowTooltip(false);

  // Tooltip content rendered using createPortal
  const tooltipContent = showTooltip ? (
    <div
      className="fixed bg-white border border-gray-300 rounded-lg shadow-lg p-3 text-sm text-gray-800"
      style={{
        top: tooltipPosition.y,
        left: tooltipPosition.x,
        maxWidth: "300px",
        zIndex: 1000,
      }}
    >
      {content}
    </div>
  ) : null;

  return (
    <div className={"relative inline-block "+className}>
      {/* Tooltip trigger for click/touch */}
      <div
        onClick={handleInteraction}
        onTouchStart={handleInteraction}
        className="cursor-pointer"
      >
        <Icon name="information-circle" size="20px" strokeColor={strokeColor}/>
      </div>

      {/* Render tooltip content with createPortal */}
      {ReactDOM.createPortal(tooltipContent, document.body)}

      {/* Overlay for closing the tooltip on mobile */}
      {showTooltip && (
        <div
          className="fixed inset-0"
          style={{ zIndex: 999 }}
          onClick={closeTooltip}
          onTouchStart={closeTooltip}
        />
      )}
    </div>
  );
};

export default TooltipModal;
