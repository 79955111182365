import { request } from "./utils/api";
import qs from "qs";
import { toast } from "react-toastify";

import {
  BureaucracyResultPortaleAlloggiati,
  BureaucracyCredsGuardiaCivil,
  AccountPerms,
  MapGuardiaCivil,
  SingleApiResponseV3,
  LocalService,
  DataTableQueryProps,
  DashboardStats,
  Local,
  LocalTable,
  TableFilterColumn,
  TableResponse,
  DataTableQueryColumn,
  LocalStatsParams,
  LocalStats,
  SingleApiResponse,
  ListApiResponse,
  StripeAccount,
  SuccessApiResponse,
  Subscription,
  PaymentMethod,
  ListApiResponseData,
  LocalAccess,
  LocalDescription,
  LocalDescriptionData,
  ServiceTable,
  ApiIntegrationItem,
  ListApiResponseV3,
  Buyable,
  ReservationTable,
  ReservationNote,
  Integration,
  IntegrationApartmentLink,
  IntegrationApartment,
  OrderTable,
  Order,
  SuccessInsertUpdateResponse,
  ProfileNotification,
  Contract,
  BureaucracyCreds,
  CustomEmail,
  ReceiptTable,
  BureaucracyCredsMossos,
  BureaucracyCredsPoliciaNational,
  Invoice,
  BillingAddress,
  Account,
  Permission,
  CustomCheckin,
  DomoticDevice,
  DomoticRule,
  DomoticEntity,
  AlloggiatiUser,
  RiceStatTable,
  AlloggiatiTable,
  PoliciaTable,
  MapMossos,
  TableSort,
  Token,
  ListApiResponseDataPaginated,
  DeviceCommandRequest,
  ReservationService,
  SmsAddon,
  GalleryItem,
  GalleryUpload,
  OrderStats,
  DomoticGraphResponse,
  PaymentCheck,
  DefaultEmail,
  ReservationV3,
  CountryContainer,
  CheckInContent,
  ApartmentNotification,
  BureaucracyCredsRossMille,
  SefTable,
  BureaucracyCredsRadar,
  ListApiResponseDataPaginatedV3,
  BureaucracyPaytourist,
  BureaucracyResultPaytourist,
  PaytouristTable,
  BureaucracyIstatTrento,
  IstatTrentoTable,
  LogoUploadGet,
  LocalUploadGet,
  IstatSiciliaTable,
  BureaucracyCredsSesHospedajes,
  SesHospedajesResultTable,
  Thread,
  Message,
  UploadedFile,
  MessageContextDataLocal,
  ApiResponse,
  MessageContextData,
  FaqEntry,
  MessageUploadAttachments,
  MessageAttachments,
  DraftThread,
  BroadcastSelectedBooking,

} from "../types";
import {
  mapAlloggiati,
  mapLocal,
  mapMossos,
  mapGuardiaCivil,
  mapOrder,
  mapPolicia,
  mapReceipt,
  mapReservation,
  mapRiceStat,
  mapService,
  mapRossMille,
  mapSef,
  mapRadar,
  mapUby,
  mapPaytourist,
  mapIstatTrento,
  mapIcalEvents,
  mapIstatSicilia,
  mapSesHospedajes,
  mapMessageThreads,
  mapMessage,
  mapUploadedFile,
  mapMessageContextDataListItem,
  mapSingleMessageContextData,
  mapMessageChannexAttachment,
  mapDraftMessageThread
} from "./utils/mappers";
import { getAuthToken, getOldToken } from "./utils/tokenStore";
import { Transaction } from "@sentry/tracing";
import dayjs from "dayjs";
import i18n from "../langs";

export async function fetchLocals(
  filters?: TableFilterColumn[] | null,
  page?: number,
  sort?: TableSort[],
  lengthInterested?: number
): Promise<TableResponse<LocalTable>> {
  const params = {
    columns: [],
    searchable: false,
    start: page || 0,
    length: lengthInterested ? lengthInterested : 15,
    _: 1610033850975,
  } as DataTableQueryProps;

  if (filters && filters.length > 0) {
    filters
      .filter((c) => c.active)
      .map((filter: TableFilterColumn, index: number) => {
        params.columns[parseInt(filter.key)] = {
          data: filter.key,
          name: filter.key,
          searchable: true,
          orderable: false,
          search: {
            value: filter.value,
            regex: filter.regex || false,
          },
        } as DataTableQueryColumn;
      });
  }

  if (sort && Array.isArray(sort) && sort.length > 0) {
    params.order = [
      {
        column: sort[0].columnIndex,
        dir: sort[0].desc ? "desc" : "asc",
      },
    ];
  }

  const response = await request({
    method: "GET",
    path: "/api/v3/local/dt?" + qs.stringify(params),
  });
  const data = await response.json();
  if (!response.ok) {
    const error = await response.json();
    toast.error(error.msg ? error.msg : error.message);
    throw new Error(error.msg ? error.msg : error.message);
  }
  return {
    recordsFiltered: data.recordsFiltered,
    recordsTotal: data.recordsTotal,
    data: data.data.map((d: any) => {
      return mapLocal(d) as LocalTable;
    }) as LocalTable[],
  } as TableResponse<LocalTable>;
}

export async function signAsAdmin(
  userKey: string,
  apiToken: string
): Promise<Token> {
  const response = await request({
    method: "POST",
    path: "/api/v3/auth/loginadmin",
    useOldToken: true,
    body: {
      user_key: userKey,
      apitoken: apiToken,
    },
  });
  return (await response.json()) as Token;
}

export async function fetchLocalSmsAddon(
  localKey: string
): Promise<SingleApiResponseV3<SmsAddon>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/local/vikeyplussmsnotif?local_key=" + localKey,
  });
  return (await response.json()) as SingleApiResponseV3<SmsAddon>;
}

export async function fetchGallery(
  element: string,
  key: string,
  value: string
): Promise<ListApiResponseData<GalleryItem>> {
  const response = await request({
    method: "GET",
    path: `/api/uploadimage?element=${element}&${key}=${value}`,
    useOldToken: true,
  });
  return (await response.json()) as ListApiResponseData<GalleryItem>;
}
export async function fetchGalleryV3(
  element: string,
  key: string,
  value: string
): Promise<SingleApiResponseV3<GalleryItem[]>> {
  const response = await request({
    method: "GET",
    path: `/api/v3/user/uploadimage?element=${element}&${key}=${value}`,
    useOldToken: true,
  });
  return (await response.json()) as SingleApiResponseV3<GalleryItem[]>;
}

export async function uploadGallery(
  element: string,
  key: string,
  value: string,
  idx: string,
  file: string
): Promise<SingleApiResponse<GalleryUpload>> {
  const form = new FormData();
  form.append("idx", idx);
  form.append("element", element);
  form.append(key, value);
  form.append("file", file);
  const response = await request({
    method: "POST",
    path: `/api/uploadimage?element=${element}&${key}=${value}`,
    form: form,
    useOldToken: true,
  });
  return (await response.json()) as SingleApiResponse<GalleryUpload>;
}
/* LOGO API */
export async function uploadGalleryV3(body: {
  local_key: string;
  file?: string;
  element: string;
  idx: any;
  color?: string;
  text?: string;
}): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/user/uploadimage",
    body: body,
    useOldToken: true,
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function deleteGalleryV3(body: {
  local_key: string;
  element: string;
  idx: any;
}): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/user/uploadimage",
    body: body,
    useOldToken: true,
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}
export async function positionGalleryV3(body: {
  id: any,
  new_idx: any
}): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/user/uploadimage",
    body: body,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(error.msg ? error.msg : error.message);
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchLocalLogo(
  local_key: string
): Promise<SingleApiResponseV3<LogoUploadGet>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/local/custom_guest_page?local_key=" + local_key,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(error.msg ? error.msg : error.message);
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<LogoUploadGet>;
}
export async function fetchLogoLocals(): Promise<
  ListApiResponseV3<LocalUploadGet>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/local/custom_local",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(error.msg ? error.msg : error.message);
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<LocalUploadGet>;
}

export async function uploadLocalLogo(body: {
  locals: any[];
  color: string;
  logo1: any;
  logo2: any;
}): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/local/custom_guest_page",
    body: body,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(error.msg ? error.msg : error.message);
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function deleteLocalLogo(body: {
  local_key: string;
}): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/local/custom_guest_page",
    body: body,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(error.msg ? error.msg : error.message);
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function uploadServiceCover(
  file: any
): Promise<SingleApiResponse<any>> {
  const form = new FormData();
  form.append("element", "SERVICE");
  form.append("file", file);
  form.append("name", "filename");
  const response = await request({
    method: "POST",
    path: `/api/uploadimage`,
    useOldToken: true,
    form: form,
  });
  return await response.json();
}

export async function saveLocalSmsAddon(
  localKey: string,
  action: "enable" | "disable"
): Promise<SingleApiResponseV3<SmsAddon>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/local/vikeyplussmsnotif?local_key=" + localKey,
    body: {
      local_key: localKey,
      action,
    },
  });
  return (await response.json()) as SingleApiResponseV3<SmsAddon>;
}

export async function pickRatePlanBkEngine(
  {
    local_key,
    rate_plan_id
  } : {
    local_key:string,
    rate_plan_id: string
  }
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/bkengine/rate_plan",
    body: {
      local_key: local_key,
      rate_plan_id: rate_plan_id,
    },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function getBookingEngineLocalDetail(local_key: string): Promise<
  SingleApiResponse<any>
> {
  const response = await request({
    method: "GET",
    path: `/api/v3/bkengine/locals?local_key=${local_key}`,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<any>;
}
export async function postBookingEngineLocalDetail(body: any): Promise<
  SingleApiResponse<any>
> {
  const response = await request({
    method: "POST",
    path: `/api/v3/bkengine/locals`,
    useOldToken: true,
    body: body
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<any>;
}

export async function fetchLocalsWithDevices(): Promise<
  ListApiResponseV3<LocalTable>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/domotics/locals",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<LocalTable>;
}

export async function fetchAllLocals(): Promise<
  ListApiResponseData<LocalTable>
> {
  const response = await request({
    method: "GET",
    path: "/api/locals",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<LocalTable>;
}

export async function fetchV3AllLocals(
  body?: any
): Promise<ListApiResponseV3<LocalTable>> {
  const response = await request({
    method: "GET",
    path: body
      ? body.limit && body.page
        ? `/api/v3/local/locals?limit=${body.limit}&page=${body.page}&search=${body.search}`
        : "/api/v3/local/locals"
      : "/api/v3/local/locals",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<LocalTable>;
}

export async function fetchRossMilleLocals(
  body: any
): Promise<ListApiResponseV3<LocalTable>> {
  const response = await request({
    method: "GET",
    path: body
      ? body.limit && body.page
        ? `/api/v3/pa/ross1000/creds/locals?limit=${body.limit}&page=${body.page}&search=${body.search}`
        : "/api/v3/pa/ross1000/creds/locals"
      : "/api/v3/pa/ross1000/creds/locals",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<LocalTable>;
}
export async function fetchPaytouristLocals(
  body: any
): Promise<ListApiResponseV3<LocalTable>> {
  const response = await request({
    method: "GET",
    path: body
      ? body.limit && body.page
        ? `/api/v3/pa/paytourist/creds/locals?limit=${body.limit}&page=${body.page}&search=${body.search}`
        : "/api/v3/pa/paytourist/creds/locals"
      : "/api/v3/pa/paytourist/creds/locals",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<LocalTable>;
}
export async function fetchIstatTrentoLocals(
  body: any
): Promise<ListApiResponseV3<LocalTable>> {
  const response = await request({
    method: "GET",
    path: body
      ? body.limit && body.page
        ? `/api/v3/pa/trento/creds/locals?limit=${body.limit}&page=${body.page}&search=${body.search}`
        : "/api/v3/pa/trento/creds/locals"
      : "/api/v3/pa/trento/creds/locals",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<LocalTable>;
}
export async function fetchRadarLocals(
  body: any
): Promise<ListApiResponseV3<LocalTable>> {
  const response = await request({
    method: "GET",
    path: body
      ? body.limit && body.page
        ? `/api/v3/pa/radar/creds/locals?limit=${body.limit}&page=${body.page}&search=${body.search}`
        : "/api/v3/pa/radar/creds/locals"
      : "/api/v3/pa/radar/creds/locals",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<LocalTable>;
}
export async function fetchV3AllLocalsSef(
  body: any
): Promise<ListApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: body
      ? body.limit && body.page
        ? `/api/v3/pa/sef/creds/locals?limit=${body.limit}&page=${body.page}&search=${body.search}`
        : "/api/v3/pa/sef/creds/locals"
      : "/api/v3/pa/sef/creds/locals",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<any>;
}
export async function fetchV3AllLocalsUby(
  body: any
): Promise<ListApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: body
      ? body.limit && body.page
        ? `/api/v3/pa/czech_uby/creds/locals?limit=${body.limit}&page=${body.page}&search=${body.search}`
        : "/api/v3/pa/czech_uby/creds/locals"
      : "/api/v3/pa/czech_uby/creds/locals",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<any>;
}

/* start SERVIZI */
export async function fetchServices(
  filters: TableFilterColumn[] | null,
  page?: number,
  sort?: TableSort[]
): Promise<TableResponse<ServiceTable>> {
  const params = {
    columns: [],
    searchable: false,
    start: page || 0,
    length: 15,
    _: 1610033850975,
  } as DataTableQueryProps;

  if (sort && Array.isArray(sort) && sort.length > 0) {
    params.order = [
      {
        column: sort[0].columnIndex,
        dir: sort[0].desc ? "desc" : "asc",
      },
    ];
  }

  if (filters && filters.length > 0) {
    filters
      .filter((c) => c.active)
      .map((filter: TableFilterColumn, index: number) => {
        params.columns[parseInt(filter.key)] = {
          data: filter.key,
          name: filter.key,
          searchable: true,
          orderable: false,
          search: {
            value: filter.value,
            regex: filter.regex,
          },
        } as DataTableQueryColumn;
      });
  }

  const response = await request({
    method: "GET",
    path: "/api/v3/pay/services/dt?" + qs.stringify(params),
  });
  const data = await response.json();
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return {
    recordsFiltered: data.recordsFiltered,
    recordsTotal: data.recordsTotal,
    data: data.data.map((d: any) => {
      return mapService(d) as ServiceTable;
    }) as ServiceTable[],
  } as TableResponse<ServiceTable>;
}

export async function fetchServiceContent(
  id: string | undefined
): Promise<ListApiResponseData<Buyable>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pay/services?id=" + id,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<Buyable>;
}

export async function updateApartmentServices(
  id: string,
  status: {
    enable_services: boolean;
    external_services_notif: boolean;
  }
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "PUT",
    useOldToken: true,
    path: "/api/v3/local/locals",
    body: { ...status, local_key: id },
  });

  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }

  return (await response.json()) as SuccessApiResponse;
}
export async function updateLocalKeysCustoms(
  local_keys: string[],
  customs: any
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "PUT",
    useOldToken: true,
    path: "/api/v3/local/update_locals",
    body: { ...customs, local_keys: local_keys },
  });

  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }

  return (await response.json()) as SuccessApiResponse;
}
export async function fetchLocalServices(
  id: string | undefined
): Promise<ListApiResponse<LocalService>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pay/items?local_key=" + id,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponse<LocalService>;
}

export async function fetchReservationServices(
  id: string | undefined
): Promise<ListApiResponseV3<ReservationService>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pay/services?resv_key=" + id,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<ReservationService>;
}

export async function deleteReservationService(
  id: string | undefined,
  resv_key?: string | undefined
): Promise<SuccessApiResponse> {
  const req = await request({
    method: "DELETE",
    path: resv_key
      ? "/api/v3/pay/services?id=" + id + "&resv_key=" + resv_key
      : "/api/v3/pay/services?id=" + id,
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) {
    const error = await req.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return response;
}

export async function refundReservationService(
  id: string | undefined,
  resv_key?: string | undefined
  // data: any
): Promise<SuccessApiResponse> {
  const req = await request({
    method: "POST",
    body: {
      id: id,
      resv_key: resv_key
    },
    path: "/api/v3/pay/refund",
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) {
    const error = await req.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return response;
}

export async function captureDepositInfoService(
  id: string | undefined,
  resv_key?: string
  // data: any
): Promise<SuccessApiResponse> {
  const req = await request({
    method: "GET",
    path: resv_key
      ? "/api/v3/pay/capture?id=" + id + "&resv_key=" + resv_key
      : "/api/v3/pay/capture?id=" + id,
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) {
    const error = await req.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return response;
}

export async function captureDepositService(
  data: {id:string, amount?:number, deposit_reason?:string},
  resv_key?: string
  // data: any
): Promise<SuccessApiResponse> {
  const req = await request({
    method: "POST",
    body: {
      id: data.id,
      amount: data?.amount,
      deposit_reason: data?.deposit_reason,
      resv_key: resv_key
    },
    path: "/api/v3/pay/capture",
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) {
    const error = await req.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return response;
}

export async function deleteCaptureDepositService(
  id: string | undefined,
  resv_key?: string | undefined
  // data: any
): Promise<SuccessApiResponse> {
  const req = await request({
    method: "DELETE",
    body: {
      id: id,
      resv_key: resv_key
    },
    path: "/api/v3/pay/capture",
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) {
    const error = await req.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return response;
}

export async function storeService(
  data: any
): Promise<SingleApiResponseV3<ReservationService>> {
  const req = await request({
    method: "PUT",
    body: data,
    path: "/api/v3/pay/services",
  });

  const response =
    (await req.json()) as SingleApiResponseV3<ReservationService>;
  if (!req.ok) {
    const error = await req.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return response;
}
export async function updateService(
  id: number | string,
  data: any
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "PUT",
    body: data,
    useOldToken: true,
    path: "/api/v3/pay/services",
  });

  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }

  return (await response.json()) as SuccessApiResponse;
}

export async function updateServiceData(
  data: any
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "POST",
    body: data,
    useOldToken: true,
    path: "/api/v3/pay/services",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }

  return (await response.json()) as SuccessApiResponse;
}

export async function fetchOrdersExport(data: any): Promise<any> {
  const url = `/api/v3/pay/services/export?${qs.stringify(data)}`;
  const response = await request({
    method: "GET",
    path: url,
  });
  // var blob = await response.blob();
  // var urlObj = window.URL.createObjectURL(blob);
  // var a = document.createElement('a');
  // a.href = urlObj;
  // a.download = 'orders.csv';//`orders_${data.date_from_gt}_${data.date_from_lt}.csv`;
  // document.body.appendChild(a);
  // a.click();
  // a.remove();

  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }

  var file = (await response.json()) as any;
  var a = document.createElement("a");
  a.setAttribute(
    "href",
    "data:text/tex;charset=utf-8," + encodeURIComponent(file.results.content)
  );
  a.setAttribute("download", file.results.filename);
  a.click();
  a.remove();
}

export async function fetchServicesCats(): Promise<ListApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pay/service_cats",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<ReservationService>;
}

/* end SERVIZI */

export async function fetchDashboardStats(): Promise<DashboardStats> {
  const response = await request({
    method: "GET",
    path: "/api/v3/user/dash_stats",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as DashboardStats;
}

export async function fetchDirectLocals(
  body?: any
): Promise<ListApiResponse<Local>> {
  const response = await request({
    method: "GET",
    path: body
      ? body.limit && body.page
        ? `/api/v3/local/locals?limit=${body.limit}&page=${body.page}`
        : "/api/v3/local/locals?include_is_bridge=true"
      : "/api/v3/local/locals?include_is_bridge=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponse<Local>;
}

export async function fetchApartment(
  id: string | undefined
): Promise<SingleApiResponseV3<Local>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/local/locals?local_key=" + id,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<Local>;
}

export async function fetchRevenueData(
  local_key: string
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/channel/smart_pricing?local_key=" + local_key,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchRevenueManagerLocal(
  local_key: string
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/channel/revenue_manager?local_key=" + local_key,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchRevenueManagerListingId(
  local_key: string
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/channel/listing_id?local_key=" + local_key,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function sendRevenueManagerLocal(
  body: {
    revenueObject: any,
    local_key: string
  }
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/channel/revenue_manager?local_key=" + body.local_key,
    body: body.revenueObject
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}
export async function editRevenueManagerLocal(
  body: {
    revenueObject: any,
    local_key: string
  }
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/channel/revenue_manager?local_key=" + body.local_key,
    body: body.revenueObject
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function updateApartment(
  id: string | number,
  data: any
): Promise<SuccessApiResponse> {
  const form = new FormData();
  form.append("local_key", id.toString());
  Object.keys(data).forEach((key) => {
    form.append(key, data[key]);
  });
  const response = await request({
    method: "PUT",
    form: form,
    useOldToken: true,
    path: "/api/locals",
  });

  return (await response.json()) as SuccessApiResponse;
}

export async function updateApartmentV3(
  id: string | number,
  data: any
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "PUT",
    body: { ...data, local_key: id },
    useOldToken: true,
    path: "/api/v3/local/locals",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }

  return (await response.json()) as SuccessApiResponse;
}

export async function updateRicestat(data: any): Promise<SuccessApiResponse> {
  const response = await request({
    method: "POST",
    body: data,
    path: `	/api/v3/pa/ricestat/creds/locals`,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function updateUby(data: any): Promise<SuccessApiResponse> {
  const response = await request({
    method: "POST",
    body: data,
    path: `	/api/v3/pa/czech_uby/creds/locals`,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}
export async function updatePaytourist(data: any): Promise<SuccessApiResponse> {
  const response = await request({
    method: "POST",
    body: data,
    path: `	/api/v3/pa/paytourist/creds/locals`,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}
export async function updateIstatTrento(
  data: any
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "POST",
    body: data,
    path: `	/api/v3/pa/trento/creds/locals`,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function updateIstatSicilia(
  data: any
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "POST",
    body: data,
    path: `	/api/v3/pa/sicilia/creds/locals`,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function fetchApartmentStats(
  local_key: string | undefined,
  from: string,
  to: string
): Promise<SingleApiResponse<LocalStats>> {
  const params = {
    local_key,
    from,
    to,
  } as LocalStatsParams;

  const response = await request({
    method: "GET",
    path: "/api/v3/local/graphstats?" + qs.stringify(params),
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<LocalStats>;
}

export async function fetchStripeAccounts(
  id?: string | undefined
): Promise<any> {
  let url = "/api/v3/pay/stripe";

  if (id) {
    url = url + "?local_key=" + id;
  }

  const response = await request({
    method: "GET",
    path: url,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as any;
}

export async function setStripeAccount(
  data: any
): Promise<ListApiResponseData<StripeAccount>> {
  const form = new FormData();
  Object.keys(data).forEach((key) => {
    form.append(key, data[key]);
  });
  const response = await request({
    method: "PUT",
    path: "/api/v3/pay/stripe",
    useOldToken: true,
    body: data,
  });
  return (await response.json()) as ListApiResponseData<StripeAccount>;
}
export async function setStripeExpressAccount(): Promise<any> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pay/stripe",
    useOldToken: true,
  });
  return (await response.json()) as any;
}

export async function setStripeExpressAccountV3(id: string): Promise<any> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pay/express_dashboard?id="+id,
    useOldToken: true,
  });
  return (await response.json()) as any;
}

export async function deleteStripeAccount(
  id: string | number
): Promise<ListApiResponseData<StripeAccount>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pay/stripe?id=" + id,
    useOldToken: true,
  });
  return (await response.json()) as ListApiResponseData<StripeAccount>;
}

export async function fetchSubscription(
  id: string | undefined
): Promise<ListApiResponse<Subscription>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/bill/subscription?local_key=" + id,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponse<Subscription>;
}

export async function fetchPaymentMethod(): Promise<
  ListApiResponse<PaymentMethod>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/bill/paymentmethod",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponse<PaymentMethod>;
}

export async function fetchGoogleSearch(body: {
  text: string;
  id?: any;
}): Promise<ListApiResponse<PaymentMethod>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/local/address_autocomplete",
    body: body.id
      ? {
          id: body.id,
          text: body.text,
          status: "confirmed",
          sessionToken: getAuthToken(),
        }
      : {
          text: body.text,
          status: "request",
          sessionToken: getAuthToken(),
        },
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponse<PaymentMethod>;
}

export async function storePaymentMethod(
  type: string,
  payment_method: string
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "POST",
    path: "/api/v3/bill/paymentmethod",
    body: {
      pmtype: type,
      setupIntent: { payment_method: payment_method },
      sourceData: { id: payment_method },
    },
  });
  return (await response.json()) as SuccessApiResponse;
}

export async function setupIntentCreate(): Promise<
  SuccessApiResponse
> {
  const req = await request({
    method: "GET",
    path: "/api/v3/bill/setupintent",
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function forcePayment(): Promise<SuccessApiResponse> {
  const req = await request({
    method: "POST",
    path: "/api/v3/bill/billattempt",
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchLocalAccess(
  id: string | undefined
): Promise<ListApiResponse<LocalAccess>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/user/permissions?local_key=" + id,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponse<LocalAccess>;
}

export async function fetchAlloggiatiUser(): Promise<
  ListApiResponseData<AlloggiatiUser>
> {
  const response = await request({
    method: "GET",
    path: "/api/alloggiatiuser",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<AlloggiatiUser>;
}

export async function addUserAccess(data: any): Promise<SuccessApiResponse> {
  const response = await request({
    method: "POST",
    path: "/api/v3/user/permissions",
    useOldToken: true,
    body: data,
  });
  if (response.status === 400) {
    const error = await response.json();
    return Promise.reject(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}
export async function updateUserAccess(data: any): Promise<SuccessApiResponse> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/user/permissions",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function deleteUserAccess(data: any): Promise<SuccessApiResponse> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/user/permissions",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function fetchLocalDescriptions(
  id: string | undefined
): Promise<SingleApiResponseV3<LocalDescription>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/user/custom_descriptions?local_key=" + id,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<LocalDescription>;
}

export async function fetchCustomTextsLocal(
  id: string | undefined,
  content: string | undefined,
  userKey: string | undefined
): Promise<SingleApiResponseV3<LocalDescription>> {
  const response = await request({
    method: "GET",
    path:
      "/api/v3/user/custom_lang?content_key=" +
      content +
      "&local_key=" +
      id +
      "&user_key=" +
      userKey,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<LocalDescription>;
}

export async function saveContentLang({
  translation,
  content_key,
  local_key,
  user_key,
}: {
  translation: LocalDescriptionData;
  content_key: string;
  local_key: string;
  user_key: string;
}): Promise<SingleApiResponse<any>> {
  let url = "/api/v3/user/custom_lang?";

  const response = await request({
    method: translation.DEL ? "DELETE" : "POST",
    path: url,
    useOldToken: true,
    body: {
      lang: translation.lang,
      user_key: user_key,
      content_key: content_key,
      local_key: local_key,
      DEL: false,
      text: translation.text,
      date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      id: translation.lang,
      uselang: true,
    },
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg ? error.msg : error.message);
  }

  return (await response.json()) as SingleApiResponse<any>;
}

export async function saveLocalDescription({
  localKey,
  translation,
}: {
  localKey: string | undefined;
  translation: LocalDescriptionData;
}): Promise<SingleApiResponse<LocalDescription>> {
  const response = await request({
    method: translation.DEL ? "DELETE" : "POST",
    path: "/api/v3/user/custom_descriptions?local_key=" + localKey,
    body: {
      local_key: localKey || "",
      DEL: translation.DEL,
      lang: translation.lang,
      text: translation.text,
      id: translation?.id ? translation?.id?.toString() : null,
      uselang: true,
    },
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<LocalDescription>;
}

export async function deleteReservation(
  id: string | undefined
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/resv/resv",
    body: {
      resv_key: id,
    },
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function fetchConnectedServiceApartments(
  id: string | undefined
): Promise<ListApiResponse<LocalService>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pay/items?buyable_id=" + id,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponse<LocalService>;
}

export async function linkLocalToService(
  id: string | number | undefined,
  mandatoryLocalIds: string[],
  enabledLocalIds: string[]
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pay/items",
    body: {
      action: "linkAll",
      buyable_id: id,
      local_keys: enabledLocalIds,
      mandatory_local_keys: mandatoryLocalIds,
    },
  });
  return (await response.json()) as SuccessApiResponse;
}

// export async function fetchCustomEmails (id: string | undefined, all = false) : Promise<ListApiResponseData<ApiIntegrationItem>> {
//   const response = await request({
//     method: 'GET',
//     path: all ? '/api/customemails?keys=true' : '/api/customemails?keys=true&local_key=' + id,
//     useOldToken: true
//   })
//   return (await response.json()) as ListApiResponseData<ApiIntegrationItem>
// }

export async function fetchCustomEmails(
  id: string | undefined,
  all = false
): Promise<ListApiResponseV3<ApiIntegrationItem>> {
  const response = await request({
    method: "GET",
    path: all
      ? "/api/v3/user/custom_custptr?keys=true&objtype=EMAIL"
      : "/api/v3/user/custom_custptr?keys=true&objtype=EMAIL&local_key=" + id,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<ApiIntegrationItem>;
}

export async function fetchCustomEmail(
  id: string
): Promise<SingleApiResponseV3<CustomEmail>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/user/custom_emails?key=" + id,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<CustomEmail>;
}

export async function fetchDefaultEmail(): Promise<
  SingleApiResponse<DefaultEmail>
> {
  const response = await request({
    method: "GET",
    path: "/api/defaultemails?key=CHECKIN_GUEST&allservs=1",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<DefaultEmail>;
}

export async function sendEmail(mail_type: string, resv_id: string) {
  const response = await request({
    method: "POST",
    path:
      "/api/reservations/emails?mail_type=" +
      mail_type +
      "&resv_key=" +
      resv_id,
    useOldToken: true,
  });
  return (await response.json()) as SuccessApiResponse;
}

export async function fetchCustomCheckins(): Promise<
  ListApiResponseV3<ApiIntegrationItem>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/user/custom_custptr?keys=true&objtype=CHECKIN",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<ApiIntegrationItem>;
}

export async function fetchContracts(
  id: string | undefined,
  keys: boolean
  // objtype?: string TODO
): Promise<ListApiResponseV3<ApiIntegrationItem>> {
  let url = `/api/v3/user/custom_custptr?`;

  if (id) {
    url = url + "&objtype=CONTRACT&keys=true&local_key=" + id;
  }

  if (keys) {
    url = url + "&keys=true&objtype=CONTRACT";
  }

  const response = await request({
    method: "GET",
    path: url,
    // useOldToken: true
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<ApiIntegrationItem>;
}

export async function fetchCheckinsV3(
  id: string | undefined,
  keys: boolean
  // objtype?: string TODO
): Promise<ListApiResponseV3<ApiIntegrationItem>> {
  let url = `/api/v3/user/custom_custptr?`;

  if (id) {
    url = url + "local_key=" + id;
  }

  if (keys) {
    url = url + "&keys=true&objtype=CHECKIN";
  }

  const response = await request({
    method: "GET",
    path: url,
    // useOldToken: true
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<ApiIntegrationItem>;
}

export async function fetchContract(
  id: string
): Promise<SingleApiResponseV3<Contract>> {
  let url = `/api/v3/user/custom_contracts?key=${id}`;
  const response = await request({
    method: "GET",
    path: url,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<Contract>;
}

export async function createContractEmailCheckin(
  data: any
): Promise<SingleApiResponseV3<Contract>> {
  let url = `/api/v3/user/custom_custptr`;
  const response = await request({
    method: "POST",
    path: url,
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<Contract>;
}

export async function updateContract(
  data: any
): Promise<SingleApiResponseV3<Contract>> {
  let url = `/api/v3/user/custom_custptr`;
  const response = await request({
    method: "PUT",
    path: url,
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<Contract>;
}

export async function updateContractLanguage(
  lang: any
): Promise<SuccessApiResponse> {
  let url = `/api/v3/user/custom_contracts`;
  const response = await request({
    method: "POST",
    path: url,
    useOldToken: true,
    body: lang,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function deleteContractLanguage(
  cust_ptr: number,
  lang: string
): Promise<SuccessApiResponse> {
  let url = `/api/v3/user/custom_contracts`;
  const response = await request({
    method: "DELETE",
    path: url,
    useOldToken: true,
    body: {
      cust_ptr,
      lang,
    },
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function deleteCheckInsLanguage(
  cust_ptr: number,
  lang: string
): Promise<SuccessApiResponse> {
  let url = `/api/v3/user/custom_checkins?cust_ptr=${cust_ptr}&lang=${lang}&body=&bodyintegral=`;
  const response = await request({
    method: "DELETE",
    path: url,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function updateCheckInLanguage(
  lang: any
): Promise<SuccessApiResponse> {
  let url = `/api/v3/user/custom_checkins`;
  const response = await request({
    method: "POST",
    path: url,
    useOldToken: true,
    body: lang,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function fetchCheckins(
  id?: string | undefined
): Promise<ListApiResponseData<ApiIntegrationItem>> {
  /*  TO MOVE NEW SERVER - TODO
    const response = await request({
      method: "GET",
      path: id
        ? "/api/customcheckins?keys=true&local_key=" + id
        : "/api/customcheckins?keys=true",
      useOldToken: true,
    });
  */
  const response = await fetch(
    id
      ? "https://api.vikey.it/api/customcheckins?keys=true&local_key=" +
      id +
      "&token=" +
      getOldToken()
      : "https://api.vikey.it/api/customcheckins?keys=true&token=" +
      getOldToken(),
    {
      method: "GET",
    }
  );
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<ApiIntegrationItem>;
}

export async function fetchCheckin(
  id: string | undefined
): Promise<SingleApiResponseV3<CustomCheckin>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/user/custom_checkins?key=" + id,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<CustomCheckin>;
}

export async function fetchDefaultCheckin(): Promise<
  SingleApiResponse<CheckInContent>
> {
  const response = await request({
    method: "GET",
    path: "/api/defaultcheckins",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<CheckInContent>;
}

export async function updateCheckin(data: any): Promise<SuccessApiResponse> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/user/custom_custptr",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function fetchService(
  id: string | undefined
): Promise<ListApiResponseV3<LocalService>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pay/items?buyable_id=" + id,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<LocalService>;
}

export async function fetchReservations(
  filters?: TableFilterColumn[],
  page?: number,
  sort?: TableSort[],
  length?: number,
  action?: string
): Promise<TableResponse<ReservationTable>> {
  const params = {
    columns: [],
    searchable: false,
    start: page || 0,
    length: length ?? 15,
    _: 1610033850975,
  } as DataTableQueryProps;

  if (filters && filters.length > 0) {
    filters
      .filter((c) => c.active)
      .map((filter: TableFilterColumn, index: number) => {
        params.columns[parseInt(filter.key)] = {
          data: filter.key,
          name: filter.key,
          searchable: true,
          orderable: false,
          search: {
            value: filter.value,
            regex: filter.regex,
          },
        } as DataTableQueryColumn;
      });
  }

  if (sort && Array.isArray(sort) && sort.length > 0) {
    params.order = [
      {
        column: sort[0].columnIndex,
        dir: sort[0].desc ? "desc" : "asc",
      },
    ];
  }

  const response = await request({
    method: "GET",
    path: action ? `/api/v3/resv/dt?` + qs.stringify(params) + `&action=${action}` : "/api/v3/resv/dt?" + qs.stringify(params),
  });
  const data = await response.json();
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return {
    recordsFiltered: data.recordsFiltered,
    recordsTotal: data.recordsTotal,
    data: data.data.map((d: any) => {
      return mapReservation(d) as ReservationTable;
    }) as ReservationTable[],
  } as TableResponse<ReservationTable>;
}

// export async function fetchReservation(
//   id: string | undefined
// ): Promise<SingleApiResponse<ReservationList>> {
//   const response = await request({
//     method: 'GET',
//     path: '/api/reservations?resv_key=' + id,
//     useOldToken: true,
//   });
//   return (await response.json()) as SingleApiResponse<ReservationList>;
// }

export async function fetchReservationv3(
  id: string | undefined
): Promise<SingleApiResponseV3<ReservationV3>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/resv/resv?resv_key=" + id,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<ReservationV3>;
}

//TODO
export async function updateReservationV3(
  reservation: any
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/resv/resv",
    // useOldToken: true,
    body: reservation,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function updateReservation(
  reservation: any
): Promise<SuccessApiResponse> {
  const form = new FormData();
  Object.keys(reservation).forEach((key) => {
    form.append(key, reservation[key]);
  });
  const response = await request({
    method: "PUT",
    path: "/api/reservations",
    useOldToken: true,
    form: form,
  });
  return (await response.json()) as SuccessApiResponse;
}

// export async function storeReservationV3(reservation: ReservationV3):Promise<SuccessApiResponse>{
//   const form = new FormData();
//   Object.keys(reservation).forEach((key) => {
//     form.append(key, reservation[key]);
//   });
//   const response = await request({
//     method: 'POST',
//     path: '/api/v3/resv/resv',
//     form: form,
//   });
//   return (await response.json()) as SuccessApiResponse;
// }

export async function storeReservation(
  reservation: any
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "POST",
    path: "/api/v3/resv/resv",
    body: reservation,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SuccessApiResponse;
}

export async function fetchReservatioNotes(
  id: string | undefined
): Promise<ListApiResponse<ReservationNote>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/resv/notes/" + id,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponse<ReservationNote>;
}

export async function fetchApiImage(path: string): Promise<Blob> {
  const response = await request({
    method: "GET",
    path: path,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return await response.blob();
}

export async function fetchAccounts(): Promise<ListApiResponse<Account>> {
  const req = await request({
    method: "GET",
    path: "/api/v3/user/accounts",
  });
  const response = (await req.json()) as ListApiResponse<Account>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function deleteSubaccount(
  user_key: string | null
): Promise<ListApiResponse<Account>> {
  const req = await request({
    method: "DELETE",
    path: "/api/v3/user/accounts",
    body: { user_key: user_key },
  });
  const response = (await req.json()) as ListApiResponse<Account>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function storeAccount(
  data: any
): Promise<ListApiResponse<Account>> {
  const req = await request({
    method: "POST",
    path: "/api/v3/user/accounts",
    body: data,
  });
  const response = (await req.json()) as ListApiResponse<Account>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchPerms(): Promise<ListApiResponseV3<AccountPerms>> {
  const req = await request({
    method: "GET",
    path: "/api/v3/user/accounts?action=get_users",
  });
  const response = (await req.json()) as ListApiResponseV3<AccountPerms>;

  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchImage(path: string): Promise<Blob> {
  const headers = new Headers();
  headers.append("Authorization", "Bearer " + getAuthToken());
  const response = await fetch(path, {
    mode: "no-cors",
    headers,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return await response.blob();
}

export async function sendCommand(data: any) {
  const form = new FormData();
  Object.keys(data).forEach((key) => {
    form.append(key, data[key]);
  });
  const req = await request({
    method: "POST",
    path: "/api/v3/local/cmd",
    body: data,
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function updateCommand(data: any) {
  const form = new FormData();
  Object.keys(data).forEach((key) => {
    form.append(key, data[key]);
  });
  const req = await request({
    method: "PUT",
    path: "/api/v3/local/tools",
    body: data,
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function createReservationNote(
  id: string,
  note: string
): Promise<ListApiResponse<ReservationNote>> {
  const req = await request({
    method: "POST",
    path: "/api/v3/resv/notes/" + id,
    body: {
      body: note,
    },
  });
  const response = (await req.json()) as ListApiResponse<ReservationNote>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function sendReservationAction(
  id: string,
  mark: Number,
  action: string
): Promise<SuccessApiResponse> {
  const req = await request({
    method: "POST",
    useOldToken: true,
    path: `/api/reservations/action?resv_key=${id}&mark=${mark}&action=${action}`,
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function markReservationDocuments(
  id: string | undefined,
  documents: any
): Promise<SuccessApiResponse> {
  //const form = new FormData(); REMOVE API OLD
  let body = {};
  if (id) {
    body = {
      resv_key: id,
      ndocs: JSON.stringify(documents),
    };
  } else {
    body = {
      ndocs: JSON.stringify(documents),
    };
  }
  //if (id) form.append("resv_key", id); REMOVE API OLD
  //form.append("ndocs", JSON.stringify(documents));
  //form.append("action", "CONFIRMDOCS");
  const req = await request({
    method: "PUT",
    useOldToken: true,
    //path: `/api/reservations/action`, REMOVE API OLD
    path: `/api/v3/resv/confirm_docs`,
    body: body,
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchIntegrations(extapts_limit?: any): Promise<
  ListApiResponseDataPaginatedV3<Integration>
> {
  const req = await request({
    method: "GET",
    path: `/api/v3/integr/integrations?action=active_integrations&links=1&extapts_limit=${extapts_limit ? '10' : '100'}&extapts_page=1`,
    useOldToken: true,
  });
  const response =
    (await req.json()) as ListApiResponseDataPaginatedV3<Integration>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchIntegrationLinking(
  userid: string,
  page?: number,
  typology?: string,
  searchValue?: string,
  filterAptAss?: string
): Promise<ListApiResponseV3<IntegrationApartmentLink>> {
  let tempPath =
    "/api/v3/integr/integrations?action=active_integrations&links=1&integr_user_id=" +
    userid +
    "&extapts_limit=50&extapts_page=" +
    page;
  if (typology) {
    tempPath =
      "/api/v3/integr/integrations?action=active_integrations&links=1&integr_user_id=" +
      userid +
      "&extapts_limit=50&extapts_page=" +
      page +
      "&typology=" +
      typology;
  }
  if(filterAptAss){
    tempPath += `&filterAssociatedApt=${filterAptAss}`
  }
  if (searchValue && searchValue !== "") {
    tempPath = tempPath + "&search=" + searchValue;
  }
  const req = await request({
    method: "GET",
    // path: "/api/v3/integr/integrations?action=linking&integr_user_id=" + userid,
    path: tempPath,
    useOldToken: true,
  });
  const response =
    (await req.json()) as ListApiResponse<IntegrationApartmentLink>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchIntegrationRemote(
  userid: string,
  page?: number,
  typology?: string,
  searchValue?: string,
  filterAptAss?: string
): Promise<ListApiResponseV3<any>> {
  let tempPath =
    "/api/v3/integr/integrations?action=active_integrations&links=1&integr_user_id=" +
    userid +
    "&extapts_limit=50&extapts_page=" +
    page;
  if (typology) {
    tempPath =
      "/api/v3/integr/integrations?action=active_integrations&links=1&integr_user_id=" +
      userid +
      "&extapts_limit=50&extapts_page=" +
      page +
      "&typology=" +
      typology;
  }
  if(filterAptAss){
    tempPath += `&filterAssociatedApt=${filterAptAss}`
  }
  if (searchValue && searchValue !== "") {
    tempPath = tempPath + "&search=" + searchValue;
  }
  const req = await request({
    method: "GET",
    // path: "/api/v3/integr/integrations?action=listing_remote&integr_user_id=" + userid,
    path: tempPath,
    useOldToken: true,
  });
  const response = (await req.json()) as ListApiResponse<any>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchOrders(
  filters: TableFilterColumn[] | null,
  page?: number,
  sort?: TableSort[]
): Promise<TableResponse<OrderTable>> {
  const params = {
    columns: [],
    searchable: false,
    start: page || 0,
    length: 15,
    _: 1610033850975,
  } as DataTableQueryProps;

  if (sort && Array.isArray(sort) && sort.length > 0) {
    params.order = [
      {
        column: sort[0].columnIndex,
        dir: sort[0].desc ? "desc" : "asc",
      },
    ];
  }

  if (filters && filters.length > 0) {
    filters
      .filter((c) => c.active)
      .map((filter: TableFilterColumn, index: number) => {
        params.columns[parseInt(filter.key)] = {
          data: filter.key,
          name: filter.key,
          searchable: true,
          orderable: false,
          search: {
            value: filter.value,
            regex: filter.regex,
          },
        } as DataTableQueryColumn;
      });
  }

  const response = await request({
    method: "GET",
    path: "/api/v3/pay/orders/dt?" + qs.stringify(params),
  });
  const data = await response.json();
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return {
    recordsFiltered: data.recordsFiltered,
    recordsTotal: data.recordsTotal,
    data: data.data.map((d: any) => {
      return mapOrder(d) as OrderTable;
    }) as OrderTable[],
  } as TableResponse<OrderTable>;
}

export async function fetchOrder(
  id: string | undefined
): Promise<SingleApiResponseV3<Order>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pay/single?payattempt_id=" + id,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<Order>;
}

export async function updateIntegrationLocals(
  data: any
): Promise<SuccessApiResponse> {
  const form = new FormData();
  Object.keys(data).forEach((key) => {
    form.append(key, data[key]);
  });
  const req = await request({
    method: "PUT",
    path: "/api/v3/integr/integrations_locals",
    useOldToken: true,
    body: data,
  });
  const response = (await req.json()) as SuccessApiResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function createIntegration(
  data: any
): Promise<SuccessInsertUpdateResponse> {
  const form = new FormData();
  Object.keys(data).forEach((key) => {
    form.append(key, data[key]);
  });
  const req = await request({
    method: "POST",
    path: "/api/v3/integr/integrations",
    useOldToken: true,
    body: data,
  });

  const response = (await req.json()) as SuccessInsertUpdateResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function createIntegrationLocal(
  data: any
): Promise<SuccessInsertUpdateResponse> {
  const form = new FormData();
  Object.keys(data).forEach((key) => {
    form.append(key, data[key]);
  });
  const req = await request({
    method: "POST",
    path: "/api/v3/integr/integrations_locals",
    useOldToken: true,
    body: data,
  });

  const response = (await req.json()) as SuccessInsertUpdateResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}
export async function deleteConnection(
  data: any
): Promise<SuccessInsertUpdateResponse> {
  const req = await request({
    method: "DELETE",
    path: `/api/v3/integr/integrations`,
    useOldToken: true,
    body: data,
  });
  if (!req.ok) {
    const error = await req.json();
    throw new Error(error.msg ? error.msg : error.message);
  }
  const response = (await req.json()) as SuccessInsertUpdateResponse;
  return response;
}

export async function deleteIntegrationLocals(
  data: any
): Promise<SuccessInsertUpdateResponse> {
  const req = await request({
    method: "DELETE",
    path: `/api/v3/integr/integrations_locals`,
    useOldToken: true,
    body: data,
  });
  const response = (await req.json()) as SuccessInsertUpdateResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function importAllReservations(
  data: any
): Promise<SuccessInsertUpdateResponse> {
  const form = new FormData();
  Object.keys(data).forEach((key) => {
    form.append(key, data[key]);
  });
  const req = await request({
    method: "POST",
    path: "/api/v3/integr/integrations",
    useOldToken: true,
    body: data,
  });

  const response = (await req.json()) as SuccessInsertUpdateResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchAptNotifications(
  local_key: string
): Promise<ListApiResponseV3<ApartmentNotification>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/notif/user_email_notifications?local_key=" + local_key,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<ApartmentNotification>;
}

export async function fetchNotifications(): Promise<
  ListApiResponseV3<ProfileNotification>
> {
  const req = await request({
    method: "GET",
    path: "/api/v3/notif/user_email_notifications",
  });
  const response = (await req.json()) as ListApiResponseV3<ProfileNotification>;
  if (!req.ok) throw new Error(response.msg);
  return (await response) as ListApiResponseV3<ProfileNotification>;
}

export async function deleteNotificationEmail(
  id: string | number
): Promise<ListApiResponseV3<any>> {
  const req = await request({
    method: "DELETE",
    path: "/api/v3/notif/user_email_notifications",
    body: { id: id },
  });
  const response = (await req.json()) as ListApiResponseV3<any>;
  if (!req.ok) throw new Error(response.msg);
  return (await response) as ListApiResponseV3<any>;
}

export async function updateNotifications(
  data: any
): Promise<ListApiResponseV3<any>> {
  const req = await request({
    method: "PUT",
    path: "/api/v3/notif/user_email_notifications",
    body: data,
  });
  const response = (await req.json()) as ListApiResponseV3<any>;
  if (!req.ok) throw new Error(response.msg);
  return (await response) as ListApiResponseV3<any>;
}

export async function addNotificationEmail(
  data: any
): Promise<ListApiResponseV3<any>> {
  const req = await request({
    method: "POST",
    path: "/api/v3/notif/user_email_notifications",
    body: data,
  });
  const response = (await req.json()) as ListApiResponseV3<any>;
  if (!req.ok) {
    const error = await req.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response) as ListApiResponseV3<any>;
}

export async function updatePassword(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/user/user",
    useOldToken: true,
    body: data,
  });
  return (await response.json()) as ListApiResponseData<any>;
}

export async function updateProfile(
  data: any
): Promise<SuccessInsertUpdateResponse> {
  const req = await request({
    method: "PUT",
    path: "/api/v3/user/user",
    useOldToken: true,
    body: data,
  });
  const response = (await req.json()) as SuccessInsertUpdateResponse;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchCredsPortaleAlloggiati(): Promise<
  ListApiResponseData<BureaucracyCreds>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCreds>;
}
export async function fetchCredsPaytourist(): Promise<
  ListApiResponse<BureaucracyPaytourist>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/paytourist/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponse<BureaucracyPaytourist>;
}
export async function fetchCredsIstatTrento(): Promise<
  ListApiResponse<BureaucracyIstatTrento>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/trento/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponse<BureaucracyIstatTrento>;
}
export async function fetchCredsIstatSicilia(): Promise<ListApiResponse<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/sicilia/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponse<any>;
}
export async function fetchCredsSef(): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/sef/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<any>;
}

export async function fetchCredsRossMille(): Promise<
  ListApiResponseData<BureaucracyCredsRossMille>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/ross1000/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCredsRossMille>;
}
export async function fetchCredsRadar(): Promise<
  ListApiResponseData<BureaucracyCredsRadar>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/radar/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCredsRadar>;
}

export async function fetchCredsUby(): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/czech_uby/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<any>;
}

export async function fetchCredsMossos(): Promise<
  ListApiResponseData<BureaucracyCredsMossos>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/mossos/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCredsMossos>;
}

export async function fetchCredsGuardiaCivil(): Promise<
  ListApiResponseData<BureaucracyCredsGuardiaCivil>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/guardia_civil/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCredsGuardiaCivil>;
}

export async function fetchCredsSesHospedajes(): Promise<
  ListApiResponseData<BureaucracyCredsSesHospedajes>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/hospedajes/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCredsSesHospedajes>;
}

export async function fetchCredsPoliciaNational(): Promise<
  ListApiResponseData<BureaucracyCredsPoliciaNational>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/policianacional/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCredsPoliciaNational>;
}

export async function fetchCredsMoTo(): Promise<
  ListApiResponseData<BureaucracyCredsPoliciaNational>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/ricestat/creds",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCredsPoliciaNational>;
}

export async function deleteCredsPortaleAlloggiati(
  data: any
): Promise<ListApiResponseData<BureaucracyCreds>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<BureaucracyCreds>;
}
export async function deleteCredsPaytourist(
  data: any
): Promise<ListApiResponseData<BureaucracyPaytourist>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/paytourist/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<BureaucracyPaytourist>;
}
export async function deleteCredsIstatTrento(
  data: any
): Promise<ListApiResponseData<BureaucracyIstatTrento>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/trento/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<BureaucracyIstatTrento>;
}
export async function deleteCredsIstatSicilia(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/sicilia/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<any>;
}
export async function deleteCredsSef(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/sef/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<BureaucracyCreds>;
}

export async function deleteCredsRossMille(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/ross1000/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<any>;
}
export async function deleteCredsRadar(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/radar/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<any>;
}

export async function deleteCredsMossos(
  data: any
): Promise<ListApiResponseData<BureaucracyCredsMossos>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/mossos/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<BureaucracyCredsMossos>;
}

export async function deleteCredsGuardiaCivil(
  data: any
): Promise<ListApiResponseData<BureaucracyCredsGuardiaCivil>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/guardia_civil/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<BureaucracyCredsGuardiaCivil>;
}


export async function deleteCredsSesHospedajes(
  data: any
): Promise<ListApiResponseData<BureaucracyCredsSesHospedajes>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/hospedajes/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<BureaucracyCredsSesHospedajes>;
}


export async function deleteCredsPoliciaNacional(
  data: any
): Promise<ListApiResponseData<BureaucracyCredsPoliciaNational>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/policianacional/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<BureaucracyCredsPoliciaNational>;
}

export async function deleteCredsMotourist(
  data: any
): Promise<ListApiResponseData<BureaucracyCredsPoliciaNational>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/ricestat/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<BureaucracyCredsPoliciaNational>;
}

export async function deleteCredsUby(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/pa/czech_uby/creds",
    useOldToken: true,
    body: { id: data },
  });
  return (await response.json()) as ListApiResponseData<any>;
}

export async function fetchLastResultPortaleAlloggiati(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>;
}

export async function fetchLastResultPaytourist(
  resv_key: string | undefined
): Promise<SingleApiResponse<BureaucracyResultPaytourist>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/paytourist/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<BureaucracyResultPaytourist>;
}
export async function fetchLastResultIstatTrento(
  resv_key: string | undefined
): Promise<SingleApiResponse<IstatTrentoTable>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/paytourist/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<IstatTrentoTable>;
}
export async function fetchLastResultSef(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/sef/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>;
}

export async function fetchLastResultRossMille(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/ross1000/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}
export async function fetchLastResultRadar(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/radar/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchLastResultRicestat(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/ricestat/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>;
}

export async function fetchLastResultMossos(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/mossos/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>;
}
export async function fetchLastResultUby(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/czech_uby/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>;
}

export async function fetchLastResultSesHospedajes(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/hospedajes/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>;
}

export async function fetchLastResultGuardiaCivil(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>> {
  const response = await request({
    method: "GET",
    path:
      "/api/v3/pa/guardia_civil/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>;
}

export async function fetchLastResultPoliciaNacional(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>> {
  const response = await request({
    method: "GET",
    path:
      "/api/v3/pa/policianacional/results?resv_key=" + resv_key + "&last=true",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>;
}

export async function sendSchedinaPortaleAlloggiati(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/webhook",
    body: { resv_key: resv_key },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}
export async function sendSchedinaSef(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/sef/webhook",
    body: { resv_key: resv_key },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function sendSchedinaRicestat(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/ricestat/webhook",
    body: { resv_key: resv_key },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function sendSchedinaMossos(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/mossos/webhook",
    body: { resv_key: resv_key },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function sendSchedinaGuardiaCivil(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/guardia_civil/webhook",
    body: { resv_key: resv_key },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function sendSchedinaPoliciaNacional(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/policianacional/webhook",
    body: { resv_key: resv_key },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}
export async function sendSchedinaUby(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/czech_uby/webhook",
    body: { resv_key: resv_key },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function sendSesHospedajes(
  resv_key: string | undefined
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/hospedajes/webhook",
    body: { resv_key: resv_key },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchInvoices(
  limit?: number,
  page?: number
): Promise<any> {
  const response = await request({
    method: "GET",
    path:
      limit && page
        ? `/api/v3/bill/billinvoice?limit=${limit}&page=${page}`
        : "/api/v3/bill/billinvoice",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<Invoice>;
}

export async function fetchInvoicesById(id: number): Promise<any> {
  const response = await request({
    method: "GET",
    path: `/api/v3/bill/billinvoice/${id}`,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<Invoice>;
}

export async function fetchTransactions(
  limit?: number,
  page?: number
): Promise<any> {
  const response = await request({
    method: "GET",
    path:
      limit && page
        ? `/api/v3/bill/billattempt?page=${page}&limit=${limit}`
        : "/api/v3/bill/billattempt",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<Transaction>;
}

export async function fetchPayInvoices(): Promise<ListApiResponseV3<Invoice>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pay/payinvoice",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<Invoice>;
}

export async function storeCredsPortaleAlloggiati(
  data: any
): Promise<ListApiResponseData<BureaucracyCreds>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCreds>;
}
export async function storeCredsPaytourist(
  data: any
): Promise<ListApiResponseData<BureaucracyCreds>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/paytourist/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCreds>;
}
export async function storeCredsIstatTrento(
  data: any
): Promise<ListApiResponseData<BureaucracyCreds>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/trento/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCreds>;
}

export async function storeCredsRossMille(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/ross1000/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<any>;
}
export async function storeCredsRadar(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/radar/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<any>;
}
export async function storeCredsSef(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/sef/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<any>;
}

export async function storeCredsIstatSicilia(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/sicilia/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<any>;
}
export async function storeCredsSesHospedajes(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/hospedajes/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.exists(error.msg) ? i18n.t(error.msg) : error.msg);
    throw new Error(error.msg);
  }
  return (await response.json()) as ListApiResponseData<any>;
}

export async function storeCredsMossos(
  data: any
): Promise<ListApiResponseData<BureaucracyCredsMossos>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/mossos/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCredsMossos>;
}

export async function storeCredsGuardiaCivil(
  data: any
): Promise<ListApiResponseData<BureaucracyCredsGuardiaCivil>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/guardia_civil/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCredsGuardiaCivil>;
}

export async function storeCredsPoliciaNacional(
  data: any
): Promise<ListApiResponseData<BureaucracyCredsPoliciaNational>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/policianacional/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCredsPoliciaNational>;
}

export async function storeCredsMotourist(
  data: any
): Promise<ListApiResponseData<BureaucracyCredsPoliciaNational>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/ricestat/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<BureaucracyCredsPoliciaNational>;
}

export async function storeCredsUby(
  data: any
): Promise<ListApiResponseData<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/pa/czech_uby/creds",
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.message));
    throw new Error(error.message);
  }
  return (await response.json()) as ListApiResponseData<any>;
}

export async function updateCustomEmail(
  data: any
): Promise<SingleApiResponse<CustomEmail>> {
  let url = `/api/v3/user/custom_custptr`;
  const response = await request({
    method: "PUT",
    path: url,
    useOldToken: true,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponse<CustomEmail>;
}

export async function updateCustomEmailLanguage(
  lang: any
): Promise<SuccessApiResponse> {
  let url = `/api/v3/user/custom_emails`;
  const response = await request({
    method: "POST",
    path: url,
    useOldToken: true,
    body: lang,
  });
  return (await response.json()) as SuccessApiResponse;
}

export async function deleteEmailLanguage(
  cust_ptr: number,
  lang: string
): Promise<SuccessApiResponse> {
  let url = `/api/v3/user/custom_emails?cust_ptr=${cust_ptr}&lang=${lang}&body=&bodyintegral=`;
  const response = await request({
    method: "DELETE",
    path: url,
    useOldToken: true,
  });
  return (await response.json()) as SuccessApiResponse;
}

export async function fetchBureaucracyRecepits(
  type: string,
  filters: TableFilterColumn[] | null,
  page?: number,
  sort?: TableSort[]
): Promise<TableResponse<any>> {
  const params = {
    columns: [],
    searchable: false,
    start: page || 0,
    length: 15,
    _: 1610033850975,
  } as DataTableQueryProps;

  if (filters && filters.length > 0) {
    filters
      .filter((c) => c.active)
      .map((filter: TableFilterColumn, index: number) => {
        params.columns[parseInt(filter.key)] = {
          data: filter.key,
          name: filter.key,
          searchable: true,
          orderable: false,
          search: {
            value: filter.value,
            regex: filter.regex,
          },
        } as DataTableQueryColumn;
      });
  }
  if (sort && Array.isArray(sort) && sort.length > 0) {
    params.order = [
      {
        column: sort[0].columnIndex,
        dir: sort[0].desc ? "desc" : "asc",
      },
    ];
  }

  let url = "";

  switch (type) {
    case "portale-alloggiati":
      url = "/api/v3/pa/receipts?";
      break;
    case "paytourist":
      url = "/api/v3/pa/paytourist/results?";
      break;
    case "istat-trento":
      url = "/api/v3/pa/trento/results?";
      break;
    case "istat-sicilia":
      url = "/api/v3/pa/sicilia/results?";
      break;
    case "ross1000":
      url = "/api/v3/pa/ross1000/results?";
      break;
    case "radar":
      url = "/api/v3/pa/radar/results?";
      break;
    case "sef":
      url = "/api/v3/pa/sef/results?";
      break;
    case "policia-nacional":
      url = "/api/v3/pa/policianacional/results?";
      break;
    case "mossos-esquadra":
      url = "/api/v3/pa/mossos/results?";
      break;
    case "guardia-civil":
      url = "/api/v3/pa/guardia_civil/results?";
      break;
    case "motourist":
      url = "/api/v3/pa/ricestat/results?";
      break;
    case "uby":
      url = "/api/v3/pa/czech_uby/results?";
      break;
    case "ses-hospedajes":
      url = "/api/v3/pa/hospedajes/results?";
      break;
  }

  const response = await request({
    method: "GET",
    path: url + qs.stringify(params),
  });
  const data = await response.json();
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  if (type === "motourist") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapRiceStat(d) as RiceStatTable;
      }) as RiceStatTable[],
    } as TableResponse<RiceStatTable>;
  }

  if (type === "portale-alloggiati") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapAlloggiati(d) as AlloggiatiTable;
      }),
    } as TableResponse<AlloggiatiTable>;
  }
  if (type === "paytourist") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapPaytourist(d) as PaytouristTable;
      }),
    } as TableResponse<PaytouristTable>;
  }
  if (type === "istat-trento") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapIstatTrento(d) as IstatTrentoTable;
      }),
    } as TableResponse<IstatTrentoTable>;
  }
  if (type === "istat-sicilia") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapIstatSicilia(d) as IstatSiciliaTable;
      }),
    } as TableResponse<IstatSiciliaTable>;
  }
  if (type === "sef") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapSef(d) as SefTable;
      }),
    } as TableResponse<SefTable>;
  }

  if (type === "ross1000") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapRossMille(d) as any;
      }),
    } as TableResponse<any>;
  }

  if (type === "radar") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapRadar(d) as any;
      }),
    } as TableResponse<any>;
  }
  if (type === "uby") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapUby(d) as any;
      }),
    } as TableResponse<any>;
  }

  if (type === "policia-nacional") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapPolicia(d) as PoliciaTable;
      }),
    } as TableResponse<PoliciaTable>;
  }

  if (type === "mossos-esquadra") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapMossos(d) as MapMossos;
      }),
    } as TableResponse<MapMossos>;
  }

  if (type === "guardia-civil") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapGuardiaCivil(d) as MapGuardiaCivil;
      }),
    } as TableResponse<MapGuardiaCivil>;
  }


  if (type === "ses-hospedajes") {
    return {
      recordsFiltered: data.recordsFiltered,
      recordsTotal: data.recordsTotal,
      data: data.data.map((d: any) => {
        return mapSesHospedajes(d) as SesHospedajesResultTable;
      }),
    } as TableResponse<SesHospedajesResultTable>;
  }

  return {
    recordsFiltered: data.recordsFiltered,
    recordsTotal: data.recordsTotal,
    data: data.data.map((d: any) => {
      return mapReceipt(d) as ReceiptTable;
    }),
  } as TableResponse<ReceiptTable>;
}

export async function fetchBureaucracyRecepitsRossMille(
  page?: number,
  ross1000_results_id?: any
): Promise<TableResponse<any>> {
  const params = {
    columns: [],
    searchable: false,
    start: page || 0,
    length: 15,
    _: 1610033850975,
  } as DataTableQueryProps;

  let url = `/api/v3/pa/ross1000/results?ross1000_results_id=${ross1000_results_id}&`;

  const response = await request({
    method: "GET",
    path: url + qs.stringify(params),
  });
  const data = await response.json();
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return data;
}
export async function fetchBureaucracyRecepitsRadar(
  page?: number,
  radar_results_id?: any
): Promise<TableResponse<any>> {
  const params = {
    columns: [],
    searchable: false,
    start: page || 0,
    length: 15,
    _: 1610033850975,
  } as DataTableQueryProps;

  let url = `/api/v3/pa/radar/results?radar_results_id=${radar_results_id}&`;

  const response = await request({
    method: "GET",
    path: url + qs.stringify(params),
  });
  const data = await response.json();
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return data;
}
export async function fetchBureaucracyRecepitsIstatTrento(
  page?: number,
  trento_results_id?: any
): Promise<TableResponse<any>> {
  const params = {
    columns: [],
    searchable: false,
    start: page || 0,
    length: 15,
    _: 1610033850975,
  } as DataTableQueryProps;

  let url = `/api/v3/pa/trento/results?trento_results_id=${trento_results_id}&`;

  const response = await request({
    method: "GET",
    path: url + qs.stringify(params),
  });
  const data = await response.json();
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return data;
}

export async function fetchOrderStats(): Promise<
  ListApiResponseData<OrderStats>
> {
  const response = await request({
    method: "GET",
    path: "/api/orders/stats",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<OrderStats>;
}

export async function fetchBillingAddresses(): Promise<
  ListApiResponseData<BillingAddress>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/bill/invoiceaddress",
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<BillingAddress>;
}

export async function fetchPermissions(
  user_key: string,
  limit?: number,
  page?: number,
  search?: string
): Promise<any> {
  const response = await request({
    method: "GET",
    path:
      limit && page
        ? search && search !== ""
          ? "/api/v3/user/permissions?child_user_key=" +
          user_key +
          "&limit=" +
          limit +
          "&page=" +
          page +
          "&search=" +
          search
          : "/api/v3/user/permissions?child_user_key=" +
          user_key +
          "&limit=" +
          limit +
          "&page=" +
          page
        : "/api/v3/user/permissions?child_user_key=" + user_key, // + '&limit=10&page=1'
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  if (limit && page) {
    return await response.json();
  }
  return (await response.json()) as ListApiResponse<Permission>;
}

export async function storePermissions(
  permission: Permission
): Promise<SingleApiResponseV3<Permission>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/user/permissions",
    useOldToken: true,
    body: permission,
  });
  return (await response.json()) as SingleApiResponseV3<Permission>;
}

export async function fetchBillingAddress(
  id: string | undefined | null | number
): Promise<BillingAddress> {
  const response = await request({
    method: "GET",
    path: "/api/v3/bill/invoiceaddress?id=" + id,
    useOldToken: true,
  });
  const data = (await response.json()) as ListApiResponse<BillingAddress>;
  if (data && data.results) {
    return Promise.resolve(data.results) as Promise<any>;
  }
  return Promise.resolve({}) as Promise<BillingAddress>;
}

export async function storeBillingAddress(
  data: any
): Promise<SingleApiResponseV3<BillingAddress>> {
  const response = await request({
    method: data.id === "new" ? "POST" : "PUT",
    path: "/api/v3/bill/invoiceaddress",
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<BillingAddress>;
}

export async function fetchDomoticDevices(
  local_key?: string | null | undefined,
  device_mac?: string | null | undefined,
  tools?: boolean,
  last_value?: boolean
): Promise<ListApiResponseV3<DomoticDevice>> {
  let url = `/api/v3/domotics/devices?`;

  if (local_key) {
    url += `local_key=${local_key}&`;
  }

  if (device_mac) {
    url += `device_mac=${device_mac}&`;
  }

  if (tools) {
    url += `get_tools=true&`;
  }

  if (last_value) {
    url += "get_entities_last_value=true&";
  }

  const response = await request({
    method: "GET",
    path: url,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<DomoticDevice>;
}

export async function fetchDomoticGraph(
  local_key: string,
  device_mac: string,
  entity_type: string,
  from: string,
  to: string
): Promise<DomoticGraphResponse> {
  const url = `/api/v3/domotics/graph?local_key=${local_key}&device_mac=${device_mac}&entity_id=${entity_type}&from=${from}&to=${to}&with_offline=true`;
  const response = await request({
    method: "GET",
    path: url,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as DomoticGraphResponse;
}

export async function fetchDomoticRules(): Promise<
  ListApiResponseV3<DomoticRule>
> {
  const url = `/api/v3/domotics/rules`;
  const response = await request({
    method: "GET",
    path: url,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<DomoticRule>;
}

export async function fetchDomoticEntities(
  localKey?: string
): Promise<ListApiResponseV3<DomoticEntity>> {
  let url = `/api/v3/domotics/entities`;
  if (localKey) {
    url = `${url}?local_key=${localKey}`;
  }
  const response = await request({
    method: "GET",
    path: url,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<DomoticEntity>;
}

export async function fetchTurismoIds(): Promise<ListApiResponseV3<string>> {
  const url = `/api/v3/local/turismo5ids`;
  const response = await request({
    method: "GET",
    path: url,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<string>;
}

export async function fetchLicenseIds(): Promise<ListApiResponseV3<string>> {
  const url = `/api/v3/local/licenseIds`;
  const response = await request({
    method: "GET",
    path: url,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<string>;
}

export async function storeDomoticRule(
  data: any
): Promise<SingleApiResponseV3<DomoticRule>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/domotics/rules",
    body: data,
  });
  if (response.status === 400) {
    const error = await response.json();
    return Promise.reject(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<DomoticRule>;
}

export async function sendDomoticCommand(
  data: DeviceCommandRequest
): Promise<SingleApiResponseV3<DomoticRule>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/local/cmd",
    useOldToken: true,
    body: data,
  });
  return (await response.json()) as SingleApiResponseV3<DomoticRule>;
}

export async function updateDomoticRule(
  data: any
): Promise<SingleApiResponseV3<DomoticRule>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/domotics/rules",
    useOldToken: true,
    body: data,
  });
  if (response.status === 400) {
    const error = await response.json();
    return Promise.reject(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<DomoticRule>;
}

export async function deleteDomoticRule(
  ruleId: string,
  localKey: string
): Promise<SingleApiResponseV3<DomoticRule>> {
  const response = await request({
    method: "DELETE",
    path: `/api/v3/domotics/rules?rule_id=${ruleId}&local_key=${localKey}`,
    useOldToken: true,
  });
  return (await response.json()) as SingleApiResponseV3<DomoticRule>;
}

export async function executeDomoticRule(
  rule: any
): Promise<SingleApiResponseV3<DomoticRule>> {
  const response = await request({
    method: "POST",
    path: `/api/v3/domotics/rule_executions`,
    useOldToken: false,
    body: { rule_id: rule.id },
  });
  return (await response.json()) as SingleApiResponseV3<DomoticRule>;
}

export async function downloadReservationContract(
  resv_key: string
): Promise<any> {
  const url = `/api/v3/checkin/download/contract/${resv_key}`;
  const response = await request({
    method: "GET",
    path: url,
  });
  if (response.status === 200) {
    var blob = await response.blob();
    var urlObj = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = urlObj;
    a.download = `${resv_key}.pdf`;
    // document.body.appendChild(a);
    a.click();
    a.remove();
  } else {
    var r = (await response.json()) as any;
    toast.error(r.msg);
    throw new Error(r.msg);
  }
}
export async function fetchReservationAction(data: any): Promise<any> {
  const url = `/api/v3/resv/action?${qs.stringify(data)}`;
  const response = await request({
    method: "GET",
    path: url,
  });
  if (response.status === 200) {
    switch (data.action) {
      case "REGISTRYBOOK":
        var blob = await response.blob();
        var urlObj = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = urlObj;
        a.download = `registry_book_${data.day_from}_${data.day_to}.pdf`;
        // document.body.appendChild(a);
        a.click();
        a.remove();
        break;
      case "PORTALLOGTXT":
        var blob = await response.blob();
        var urlObj = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = urlObj;
        a.download = "portalealloggiati.txt";
        document.body.appendChild(a);
        a.click();
        a.remove();
        break;
      case "DOWNLOAD_RESV_ZIP":
        var filename = response.headers.get("filename");
        var blob = await response.blob();
        var urlObj = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = urlObj;
        a.download = filename === null ? data.resv_key : filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        break;
      case "MOSSOS":
        var file = (await response.json()) as any;
        var a = document.createElement("a");
        a.setAttribute(
          "href",
          "data:text/tex;charset=utf-8," +
          encodeURIComponent(file.results.content)
        );
        a.setAttribute("download", file.results.filename);
        // document.body.appendChild(a);
        a.click();
        a.remove();
        break;
      case "TURISMO5XML":
        var file = (await response.json()) as any;
        var a = document.createElement("a");
        a.setAttribute(
          "href",
          "data:text/tex;charset=utf-8," +
          encodeURIComponent(file.results.content.toString())
        );
        a.setAttribute("download", file.results.filename);
        a.click();
        a.remove();
        break;
      case "PAYTOURIST":
        var file = (await response.json()) as any;
        var a = document.createElement("a");
        a.setAttribute(
          "href",
          "data:text/tex;charset=utf-8," +
          encodeURIComponent(file.results.content.toString())
        );
        a.setAttribute("download", file.results.filename);
        a.click();
        a.remove();
        break;
      case "TICINO":
        var file = (await response.json()) as any;
        var a = document.createElement("a");
        a.setAttribute(
          "href",
          "data:text/tex;charset=utf-8," +
          encodeURIComponent(file.results.content.toString())
        );
        a.setAttribute("download", file.results.filename);
        a.click();
        a.remove();
        break;
      case "RESERVATIONSCSV":
        var file = (await response.json()) as any;
        var a = document.createElement("a");
        a.setAttribute(
          "href",
          "data:text/tex;charset=utf-8," +
          encodeURIComponent(file.results.content)
        );
        a.setAttribute("download", file.results.filename);
        a.click();
        a.remove();
    }
  } else {
    var r = (await response.json()) as any;
    toast.error(r.msg);
    throw new Error(r.msg);
  }
}

export async function fetchBucketItem(url: any, filename: string, ext: string) {
  const response = await request({
    method: "GET",
    path: `/api/v3${url}`,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  var blob = await response.blob();
  var urlObj = window.URL.createObjectURL(blob);
  var a = document.createElement("a");
  a.href = urlObj;
  a.download = `${filename}.${ext}`;
  // document.body.appendChild(a);
  a.click();
  a.remove();
}

/* scarico massivo delle ricevute */
export async function fetchBucketAllDocs(portal: string | any) {
  const response = await request({
    method: "GET",
    path: portal.type ? `/api/v3/pa/receipts/request?portal=${portal.name}&filter=${portal.type}`: `/api/v3/pa/receipts/request?portal=${portal}`,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
  } else {
    toast.success(i18n.t("general.operationCompleted"));
    return await response.json();
  }
}

export async function saveMossosBuruAssoc(
  data: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: `/api/v3/pa/mossos/creds/locals`,
    useOldToken: false,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}
export async function saveRossMilleBuruAssoc(
  data: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: `/api/v3/pa/ross1000/creds/locals`,
    useOldToken: false,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}
export async function saveRadarBuruAssoc(
  data: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: `/api/v3/pa/radar/creds/locals`,
    useOldToken: false,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}
export async function saveGuardiaCivilBuruAssoc(
  data: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: `/api/v3/pa/guardia_civil/creds/locals`,
    useOldToken: false,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function saveSesHospedajesBuruAssoc(
  data: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: `/api/v3/pa/hospedajes/creds/locals`,
    useOldToken: false,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function savePoliciaNacionalBuruAssoc(
  data: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: `/api/v3/pa/policianacional/creds/locals`,
    useOldToken: false,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}
export async function saveItalyBuruAssoc(
  data: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: `/api/v3/pa/creds/locals`,
    useOldToken: false,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function savePortugalBuruAssoc(
  data: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: `/api/v3/pa/sef/creds/locals`,
    useOldToken: false,
    body: data,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function forcePaymentCheck(): Promise<
  SingleApiResponseV3<PaymentCheck>
> {
  const response = await request({
    method: "GET",
    path: `/api/v3/bill/force_payment_section`,
    useOldToken: false,
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function adminLogin(
  userKey: string,
  apiToken: string
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: `/api/v3/auth/loginadmin`,
    useOldToken: false,
    body: {
      apitoken: apiToken,
      user_key: userKey,
    },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function submitTicket(
  text: string,
  object: string
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "POST",
    path: `/api/v3/user/ticket`,
    useOldToken: false,
    body: {
      body: text,
      title: object,
    },
  });
  return (await response.json()) as SuccessApiResponse;
}

export async function updateSingleDocument(
  data: any
): Promise<SingleApiResponseV3<any>> {
  const req = await request({
    method: "PUT",
    body: data,
    path: "/api/v3/resv/update_single_doc",
  });
  const response = (await req.json()) as SingleApiResponseV3<CountryContainer>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function deleteSingleDocument(
  data: any
): Promise<SuccessApiResponse> {
  const response = await request({
    method: "DELETE",
    body: data,
    path: "/api/v3/resv/update_single_doc",
  });

  return (await response.json()) as SuccessApiResponse;
}
export async function fetchCountries(): Promise<
  SingleApiResponseV3<CountryContainer>
> {
  const req = await request({
    method: "GET",
    path: "/api/v3/pa/countries",
  });
  const response = (await req.json()) as SingleApiResponseV3<CountryContainer>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchRegions(): Promise<any> {
  const req = await request({
    method: "GET",
    path: "/api/v3/pa/ross1000/regions",
  });
  const response = (await req.json()) as any;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchInvoiceCountries(): Promise<ListApiResponseV3<any>> {
  const req = await request({
    method: "GET",
    path: "/api/v3/bill/billing_countries",
  });
  const response = (await req.json()) as ListApiResponseV3<any>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchProvinces(country: string): Promise<SingleApiResponseV3<any>> {
  const req = await request({
    method: "GET",
    path: "/api/v3/pa/province" + "?country=" + country,
  });
  const response = (await req.json()) as SingleApiResponseV3<any>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}
export async function fetchProvincesPaytourist(): Promise<
  SingleApiResponseV3<any>
> {
  const req = await request({
    method: "GET",
    path: "/api/v3/pa/paytourist/regions",
  });
  const response = (await req.json()) as SingleApiResponseV3<any>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchCounties(
  country: string,
  province: string
): Promise<SingleApiResponseV3<any>> {
  const req = await request({
    method: "GET",
    path: "/api/v3/pa/comuni?country=" + country + "&provincia=" + province,
  });
  const response = (await req.json()) as SingleApiResponseV3<any>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}
export async function notificationConfirmation(
  token: string
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/user/token",
    // useOldToken: true,
    body: {
      action: "confirm",
      token: token,
    },
  });
  return await response.json(); //as SingleApiResponseV3<DomoticRule>;
}

export async function oauthCloudBeds(
  querystring: string
): Promise<SingleApiResponseV3<any>> {
  const req = await request({
    method: "GET",
    path: "/api/v3/integr/cloudbedsoauthcallback?" + querystring,
  });
  const response = (await req.json()) as SingleApiResponseV3<any>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function DeleteCustPtr(
  id: any
): Promise<ListApiResponseV3<ApiIntegrationItem>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/user/custom_custptr?key=" + id,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseV3<ApiIntegrationItem>;
}

export async function removeDocConfirmation(
  resv_key: string
): Promise<SingleApiResponseV3<any>> {
  const req = await request({
    method: "POST",
    path: "/api/v3/resv/remove_doc_confirm",
    body: {
      resv_key: resv_key,
    },
  });
  const response = (await req.json()) as SingleApiResponseV3<any>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}
export async function importDocsFromResvKey(
  from_resv_key: string,
  to_resv_key: string
): Promise<SingleApiResponseV3<any>> {
  const req = await request({
    method: "POST",
    path: "/api/v3/resv/copy_ndocs",
    body: {
      from_resv_key,
      to_resv_key,
    },
  });
  const response = (await req.json()) as SingleApiResponseV3<any>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}
export async function denyDocsResvKey(
  resv_key: any,
  guest_id: any
): Promise<SingleApiResponseV3<any>> {
  const req = await request({
    method: "POST",
    path: "/api/v3/resv/deny_doc",
    body: {
      resv_key,
      guest_id,
    },
  });
  const response = (await req.json()) as SingleApiResponseV3<any>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchAllModules(local_key?: any): Promise<any> {
  const response = await request({
    method: "GET",
    path: local_key
      ? "/api/v3/bill/modules?local_key=" + local_key
      : "/api/v3/bill/modules",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as any;
}
export async function fetchSyncJobiCal(
  local_key: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/resv/sync_ical?local_key=" + local_key,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}
export async function fetchModuleDetail(id: any): Promise<any> {
  const response = await request({
    method: "GET",
    path: "/api/v3/bill/modules?module_id=" + id,
  });
  return (await response.json()) as any;
}

export async function activateModule(
  local_key: any,
  module_id: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/bill/module/activate",
    body: {
      local_keys: local_key,
      module_id: module_id,
    },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function deactivateModule(
  local_key: any,
  module_id: any,
  reason: any,
  other: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/bill/module/deactivate",
    body: {
      local_keys: local_key,
      module_id: module_id,
      reason: reason,
      other: other,
    },
  });
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function addApartment(data: any): Promise<ListApiResponse<any>> {
  const req = await request({
    method: "POST",
    path: "/api/v3/local/owner_add_locals",
    body: data,
  });
  const response = (await req.json()) as ListApiResponse<any>;
  if (!req.ok) throw new Error(response.msg);
  return response;
}

export async function fetchAlphaCountries(): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/alpha_countries",
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchAlertsClient(): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/notif/alerts",
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchReasonDeactivation(): Promise<any> {
  const response = await request({
    method: "GET",
    path: "/api/v3/bill/churn_reasons",
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchCalendariCal(body: string[]): Promise<any> {
  const response = await request({
    method: "POST",
    path: "/api/v3/resv/ical_dt",
    body: { local: body },
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  const data = await response.json();
  return {
    results: data.results.map((d: any) => {
      return mapIcalEvents(d) as any;
    }) as any[],
  };
}

export async function fetchAllCalendars(local_key: string): Promise<any> {
  const response = await request({
    method: "GET",
    path: `/api/v3/resv/calendar_link?local_key=${local_key}`,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}
export async function fetchApartmentSettings(local_key: string): Promise<any> {
  const response = await request({
    method: "GET",
    path: `/api/v3/local/default_resv?local_key=${local_key}`,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function sendApartmentSettings(body: any): Promise<any> {
  const response = await request({
    method: "PUT",
    path: `/api/v3/local/default_resv`,
    body: body,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function deleteCalendarSync(id: any): Promise<any> {
  const response = await request({
    method: "DELETE",
    path: `/api/v3/resv/calendar_link`,
    body: { id },
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function createCalendarSync({
  channel,
  link,
  local_key,
}: any): Promise<any> {
  const response = await request({
    method: "POST",
    path: "/api/v3/resv/calendar_link",
    body: {
      channel,
      link,
      local_key,
    },
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}


/* TODO exportStatsCSV */
export async function exportStatsCSV(
  data: any
): Promise<SingleApiResponseV3<any>> {
  const req = await request({
    method: "GET",
    path: `/api/v3/local/stats_export?to=${data.to}&from=${data.from}&local_key=${data.local_key}`,
  });
  const response = (await req.json()) as SingleApiResponseV3<any>;
  if (!req.ok) throw new Error(response.msg);

  let a = document.createElement("a");
  a.setAttribute(
    "href",
    "data:text/tex;charset=utf-8," +
    encodeURIComponent(response.results.content)
  );
  a.setAttribute("download", response.results.filename);
  a.click();
  a.remove();
  return response;
}

export async function fetchTokenChannel(
  user_key?: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: user_key
      ? `/api/v3/channel/token?user_key=${user_key}`
      : "/api/v3/channel/token",
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchChannelLocals(
  limitPage: number,
  currentPage: number,
  user_key?: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: user_key
      ? limitPage && currentPage
        ? `/api/v3/channel/room?limit=${limitPage}&page=${currentPage}&user_key=${user_key}`
        : `/api/v3/channel/room?user_key=${user_key}`
      : limitPage && currentPage
        ? `/api/v3/channel/room?limit=${limitPage}&page=${currentPage}`
        : "/api/v3/channel/room",
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchChannelSingleLocal(
  local_key: string
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/channel/room?local_key=" + local_key,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function postChannelSingleLocal(
  local_key: string,
  body: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/channel/room",
    body: {
      room_type: {
        ...body,
      },
      local_key: local_key,
    },
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchRateSingleLocal(
  local_key: string,
  rate_id?: string
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: rate_id
      ? "/api/v3/channel/rate_plan?local_key=" + local_key + "&id=" + rate_id
      : "/api/v3/channel/rate_plan?local_key=" + local_key,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function createRateSingleLocal(
  local_key: string,
  body: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/channel/rate_plan",
    body: {
      local_key: local_key,
      rate_plan: {
        ...body,
      },
    },
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function editRateSingleLocal(
  local_key: string,
  body: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/channel/rate_plan",
    body: {
      local_key: local_key,
      rate_plan: {
        ...body,
      },
    },
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function deleteRateSingleLocal(
  rate_id: string,
  local_key: string
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "DELETE",
    path: "/api/v3/channel/rate_plan",
    body: { id: rate_id, local_key: local_key },
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchSettingsLocalChannex(
  rate_plan_id: string
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/channel/settings?rate_plan_id=" + rate_plan_id,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function putSettingsLocalChannex(
  body: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/channel/settings",
    body: body,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchPermissionChannel(
  user_key?: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: user_key
      ? `/api/v3/channel/status?user_key=${user_key}`
      : "/api/v3/channel/status",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchUserkeysChannel(): Promise<
  SingleApiResponseV3<any>
> {
  const response = await request({
    method: "GET",
    path: "/api/v3/channel/users",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchLastMinute(
  user_key?: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: user_key
      ? `/api/v3/channel/property?user_key=${user_key}`
      : "/api/v3/channel/property",
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function saveLastMinute(
  body: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/channel/property",
    body: body.user_key
      ? {
        user_key: body.user_key,
        property: {
          settings: {
            cut_off_time: body.cut_off_time,
            cut_off_days: body.cut_off_days,
            state_length: body.state_length,
          },
        },
      }
      : {
        property: {
          settings: {
            cut_off_time: body.cut_off_time,
            cut_off_days: body.cut_off_days,
            state_length: body.state_length,
          },
        },
      },
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchApiToken(): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: "/api/v3/user/api_token",
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function createNewApiToken(): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/user/api_token",
    body: {},
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function disableApiToken(
  idsList: any[]
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "PUT",
    path: "/api/v3/user/api_token",
    body: {
      ids: idsList,
    },
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchPhoneCodeList(): Promise<any> {
  const response = await request({
    method: "GET",
    path: "/api/v3/pa/phonenumbers/prefixes",
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}


export async function fetchRestrictions(
  days?: any,
  date?: any,
  local_key?: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "GET",
    path: `/api/v3/channel/restrictions?${days ? `days=${days}&` : ""}${date ? `date=${date}&` : ""
      }${local_key ? `local_key=${local_key}` : ""}`,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function changeRestrictions(
  values: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "PUT",
    path: `/api/v3/channel/restrictions`,
    body: values
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function changeAvailability(
  values: any
): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "PUT",
    path: `/api/v3/channel/availability`,
    body: values
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function setMultiLocals(body: {
  locals: any[];
  action: any;
  ptr_checkin?: any;
  ptr_email?: any;
  ptr_contract?: any;
  local_key?: any;
}): Promise<SingleApiResponseV3<any>> {
  const response = await request({
    method: "POST",
    path: "/api/v3/local/multiselect",
    body: body,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as SingleApiResponseV3<any>;
}

export async function fetchV3MultiSelect(
  body : {
    action:string, 
    ptr_checkin?: string,
    ptr_email?: string,
    ptr_contract?: string,
    local_key?: string
  } | any
): Promise<ListApiResponseV3<LocalTable>> {
  let newUrl = "/api/v3/local/multiselect?"
  Object.keys(body).forEach((key) => {
    newUrl += `&${key}=${body[key]}`;
  });
  const response = await request({
    method: "GET",
    path: newUrl,
    useOldToken: true,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as ListApiResponseData<LocalTable>;
}
export async function fetchMessageThreads(args: {
  page?: number;
  limit?: number;
  query?: string;
  filter?: string;
}): Promise<{ threads: Thread[]; has_unread_messages: boolean }> {
  const { page, limit, query = "", filter = "" } = args;

  const response = await request({
    method: "GET",
    path: `/api/v3/channel/threads?${page ? `page=${page}` : "page=1"}${
      limit ? `&limit=${limit}` : ""
    }${query ? `&query=${query}` : ""}${filter ? `&filter=${filter}` : ""}`,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();

  const results = JSON.parse(data.results);
  const has_unread_messages = data.has_unread_messages;

  if (Array.isArray(results)) {
    const threads = results.map(mapMessageThreads);
    return { threads, has_unread_messages };
  }

  throw new Error("Unexpected data structure: expected an array of threads.");
}


export async function fetchMessageThreadReservationNotes(args: {
  resv_key?: string;
  action?:string;
}): Promise<any> {
  const { resv_key,action } = args;
  // console.log("resv_key ",resv_key)

  const response = await request({
    method: "GET",
    path: `/api/v3/resv/notes/${resv_key}?action=${action}`,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();

  return data.results
}

export async function fetchReservationThreadAction(args: {
  resv_key?: string;
  action?:string;
}): Promise<SingleApiResponseV3<Thread>> {
  const { resv_key,action } = args;
  // console.log("resv_key ",resv_key)
  const response = await request({
    method: "GET",
    path: `/api/v3/channel/threads?resv_key=${resv_key}&action=${action}`,
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }
  const data = await response.json();
  return data
}

export async function fetchMessageThreadDrafts(args: {
  page?: number;
  limit?: number;
  query?: string;
}): Promise<ListApiResponseV3<DraftThread>>{
  const { page, limit, query = "" } = args;
  const response = await request({
    method: "GET",
    path: `/api/v3/channel/drafts?${page ? `page=${page}` : "page=1"}${
      limit ? `&limit=${limit}` : ""
    }${query ? `&query=${query}` : ""}`,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();

  return data as ListApiResponseData<DraftThread>;
}

export async function fetchSingleThread(
  thread_id: string | null
): Promise<{ thread: Thread; has_unread_messages: boolean }> {
  const response = await request({
    method: "GET",
    path: `/api/v3/channel/threads?${thread_id ? `thread_id=${thread_id}` : ""}`,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();
  const results = JSON.parse(data.results);
  const has_unread_messages = JSON.parse(data.has_unread_messages);

  if (Array.isArray(results)) {
    return {
      thread: mapMessageThreads(results[0]),
      has_unread_messages,
    };
  }

  return {
    thread: mapMessageThreads(results),
    has_unread_messages,
  };
}

export async function fetchMessages(
  thread_id: string,
  page?: number
): Promise<Message[]> {
  const response = await request({
    method: "GET",
    path: `/api/v3/channel/messages?${thread_id ? `thread_id=${thread_id}&` : ""}&${page ? `page=${page}&grouped=true` : "page=1"}`,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();
  const results = JSON.parse(data.results);


  if (Array.isArray(results)) {
    return results.map(mapMessage);
  } else if (Array.isArray(results)) {
    return data.map(mapMessage);
  } else {
    throw new Error("Unexpected data structure: expected an array of messages.");
  }
}

export async function sendMessage(thread_id: string | null | undefined, message_content: string, resv_key: string | undefined, files:MessageUploadAttachments[], broadcast?:boolean, selectedBookingsForMessage?:BroadcastSelectedBooking[]) {
  const response = await request({
    method: "POST",
    path: "/api/v3/channel/threads",
    useOldToken: true,
    body: {
      thread_id: thread_id,
      message_content: message_content,
      resv_key: resv_key,
      files:files,
      broadcast:broadcast,
      selectedBookingsForMessage:selectedBookingsForMessage
    },
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();
  if(data.results.length>0){
    const results = JSON.parse(data.results);
    return mapMessage(results);
  }
  return
}

export async function threadAction(thread_id: string,action:string) {
  const response = await request({
    method: "POST",
    path: "/api/v3/channel/thread/actions",
    useOldToken: true,
    body: {
      thread_id: thread_id,
      action: action
    },
  });


  
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();

  const results = JSON.parse(data.results);
  return mapMessageThreads(results);
}



export async function readSingleThread(
  thread_id?: string | null
): Promise<{ thread: Thread; has_unread_messages: boolean }> {
  const response = await request({
    method: "POST", 
    path: `/api/v3/channel/read_thread`,
    useOldToken: true,
    body: {
      thread_id, 
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();
  console.log(data)

  const results = JSON.parse(data.results); 
  const has_unread_messages = data.has_unread_messages;

  if (!results) {
    throw new Error("No thread data received.");
  }
  const thread = mapMessageThreads(results);
  return { thread, has_unread_messages };
}

export async function actionReadThreads(action:string): Promise<{thread_ids:string[],has_unread_messages: boolean}> {
  const response = await request({
    method: "POST", 
    path: `/api/v3/channel/read_thread`,
    useOldToken: true,
    body: {
      action:action, 
    },
    
  });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.msg || error.message);
    }

    const data = await response.json();
    // console.log(data)

    const thread_ids = data.results 
    const has_unread_messages = data.has_unread_messages;

    return { thread_ids, has_unread_messages };
}
export async function uploadFile(
  idx: number,
  context_id: string,
  element: string,
  file: string,
  fileName: string,
  local_key: string | undefined,
): Promise<UploadedFile> {
  const response = await request({
    method: "post",
    path: `/api/v3/user/uploadfile`,
    useOldToken: true,
    body: {
      idx: idx,
      context_id: context_id,
      file: file,
      file_name: fileName,
      local_key: local_key,
      element: element,
    },

  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }
  const data = await response.json();
  const results = JSON.parse(data.results);


  return mapUploadedFile(results)
}

export async function fetchSingleMessageContext(
  context_id: string,
  local_key?: string
): Promise<MessageContextData> {
  const response = await request({
    method: "GET",
    path: `/api/v3/channel/locals/assistant${context_id ? `?context_id=${context_id}&` : ""}${local_key ? `local_key=${local_key}` : ""}`,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();
  const results = JSON.parse(data.results);

  return mapSingleMessageContextData(results);
}

export async function fetchMessageContextDatas(): Promise<MessageContextDataLocal[]> {
  const response = await request({
    method: "GET",
    path: `/api/v3/channel/assistants/contexts`,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();

  const results = JSON.parse(data.results);

  if (Array.isArray(results)) {
    return results.map(mapMessageContextDataListItem);
  } else {
    throw new Error("Unexpected data structure: expected an array of messages.");
  }
}

export async function createMessageContextData(name: string) {
  const response = await request({
    method: "post",
    path: `/api/v3/channel/assistants/contexts`,
    useOldToken: true,
    body: {
      name: name,
    },
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();
  const results = JSON.parse(data.results);
  return mapMessageContextDataListItem(results);
}

export async function createOrUpdateFaqs(faqs: FaqEntry[] | null, context_id?: string) {
  if (context_id) {
    const response = await request({
      method: "post",
      path: `/api/v3/channel/locals/assistant`,
      useOldToken: true,
      body: {
        context_id: context_id,
        faqs: faqs,
      },
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.msg ? error.msg : error.message);
    }
    else {

    }
    const data = await response.json();
    return data
  }

}

export async function deleteSingleFile(file: UploadedFile) {
  const response = await request({
    method: "delete",
    path: `/api/v3/user/uploadfile`,
    useOldToken: true,
    body: {
      file_id: file.file_id,
      context_id: file.context_id
    },
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }
  const data = await response.json();

  return data

}

export async function translateSingleMessage(message_id: string | null, lang: string | undefined): Promise<Message> {
  const response = await request({
    method: "GET",
    path: `/api/v3/channel/messages/translate${message_id ? `?message_id=${message_id}&` : ""}${lang ? `lang=${lang}` : ""}`,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }

  const data = await response.json();
  const results = JSON.parse(data.results);


  return mapMessage(results)
}

export async function fetchAttachments(resv_key?:string,attachment_id?:string): Promise<any> {

  const response = await request({
    method: "GET",
    path: `/api/v3/channel/messages/attachments${attachment_id ? `?attachment_id=${attachment_id}&` : ""}${resv_key ? `resv_key=${resv_key}` : ""}`,
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.msg || error.message);
  }
  return response
}

export async function fetchLocalBkEngineServices(
  id: string | undefined
): Promise<any> {
  const response = await request({
    method: "GET",
    path: "/api/v3/bkengine/services?local_key=" + id,
  });
  if (!response.ok) {
    const error = await response.json();
    toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as any;
}

export async function fetchUserFilters(
  id: string | undefined
): Promise<any> {
  const response = await request({
    method: "GET",
    path: "/api/v3/user/key_value?key=" + id,
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as any;
}

export async function saveUserFilters(
body: {
  key: any;
  value: string;
}, method: string): Promise<any> {
  const response = await request({
    method: method,
    path: "/api/v3/user/key_value",
    body: body
  });
  if (!response.ok) {
    const error = await response.json();
    // toast.error(i18n.t(error.msg ? error.msg : error.message));
    throw new Error(error.msg ? error.msg : error.message);
  }
  return (await response.json()) as any;
}