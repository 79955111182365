import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FormField from "../FormField";
import TextInput from "../TextInput";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { useMutation, useQuery } from "react-query";
import {
  fetchPhoneCodeList,
  getBookingEngineLocalDetail,
  postBookingEngineLocalDetail,
} from "../../shared/queries";
import { toast } from "react-toastify";
import Button from "../Button";
import SelectInput from "../SelectInput";
import { BaseSelectItem } from "../../types";
import bkengineContactSchema from "../../shared/schema/bkengineContactSchema";

type Props = {
  id: string;
  apartmentDetail: any
};

const BkContacts = (props: Props) => {
  const { id, apartmentDetail } = props;
  const { t } = useTranslation();
  const { profile } = useSelector((state: RootState) => state.login);
  const [phoneList, setPhoneList] = useState<any>([]);
  const [prefixNumber, setPrefixNumber] = useState<any>();
  const [prefixNumberTwo, setPrefixNumberTwo] = useState<any>();
  const [validationError, setValidationError] = useState<any>(null);

  const [bkEngineDetail, setBkEngineDetail] = useState<{
    cin_code: string | null;
    contact_phone: string | null;
    contact_mail: string | null;
    main_contact_phone: string | null;
    main_contact_mail: string | null;
    cir_code: string | null;
  }>({
    cin_code: null,
    cir_code: null,
    contact_phone: null,
    contact_mail: null,
    main_contact_phone: null,
    main_contact_mail: null,
  });

  const {
    refetch: refetchApartmentBkEngineDetail,
    isLoading: isLoadingApartmentBkEngineDetail,
  } = useQuery<any>(
    ["bkApartmentDetailApt", id],
    () => getBookingEngineLocalDetail(id as string),
    {
      onSuccess: (data) => {
        const results = data.results;
        setBkEngineDetail({
          cin_code: results.cin_code ?? null,
          main_contact_phone: results.main_contact_phone ?? null,
          main_contact_mail: results.main_contact_mail ?? null,
          contact_phone: results.contact_phone ?? null,
          contact_mail: results.contact_mail ?? null,
          cir_code: results.cir_code ?? null
        });
      },
      enabled: !!id,
    }
  );

  const { isLoading: phoneCodeListLoading } = useQuery<any>(
    ["phoneCodeListBk"],
    () => fetchPhoneCodeList(),
    {
      onSuccess: (data) => {
        const states = Object.keys(data.results).map((d) => {
          return {
            label: `${d} (${data.results[d]})`,
            value: data.results[d],
          };
        });
        setPhoneList(states);
        return data;
      },
    }
  );

  const postBookingEngineLocalDetailMutation = useMutation(
    (body: any) => postBookingEngineLocalDetail(body),
    {
      onSuccess: () => {
        toast.success(t("general.fileUploaded"));
        refetchApartmentBkEngineDetail();
      },
      onError: (error: Error) => {
        toast.error(t(error?.message ? error.message : "general.requestError"));
        refetchApartmentBkEngineDetail();
      },
    }
  );
  return (
    <>
      {/* CONTACTS & CIN */}
      <div className="flex-row space-y-3 w-full md:w-2/3 mt-7">
        
        <div className="md:flex gap-4 md:space-y-0 space-y-3">
          {!bkEngineDetail.main_contact_phone?.includes("+") && (
            <FormField label={t("resv.prefixNumber")} className="w-full">
              <SelectInput<BaseSelectItem, false>
                isLoading={phoneCodeListLoading}
                placeholder={t("selector.selectOption")}
                value={prefixNumber}
                options={phoneList}
                onChange={(item) => {
                  if (item) {
                    setPrefixNumber(item);
                    setBkEngineDetail((prev) => {
                      return {
                        ...prev,
                        main_contact_phone: item.value,
                      };
                    });
                  }
                }}
              />
            </FormField>
          )}
          <FormField
            label={t("apartmentContent.mainContact")}
            className="w-full"
            errors={validationError}
            errorKey={"main_contact_phone"}
          >
            <TextInput
              disabled={
                postBookingEngineLocalDetailMutation.isLoading ||
                isLoadingApartmentBkEngineDetail
              }
              value={bkEngineDetail.main_contact_phone ?? ""}
              onChange={(val) => {
                setBkEngineDetail((prev) => {
                  return {
                    ...prev,
                    main_contact_phone: val,
                  };
                });
              }}
            />
          </FormField>
        </div>

        <FormField
          label={t("apartmentContent.mainEmail")}
          errors={validationError}
          errorKey={"main_contact_mail"}
        >
          <TextInput
            disabled={
              postBookingEngineLocalDetailMutation.isLoading ||
              isLoadingApartmentBkEngineDetail
            }
            value={bkEngineDetail.main_contact_mail ?? ""}
            type="email"
            onChange={(val) => {
              setBkEngineDetail((prev) => {
                return {
                  ...prev,
                  main_contact_mail: val,
                };
              });
            }}
          />
        </FormField>

        <div className="md:flex gap-4 md:space-y-0 space-y-3">
          {!bkEngineDetail.contact_phone?.includes("+") && (
            <FormField label={t("resv.prefixNumber")} className="w-full">
              <SelectInput<BaseSelectItem, false>
                isLoading={phoneCodeListLoading}
                placeholder={t("selector.selectOption")}
                value={prefixNumberTwo}
                options={phoneList}
                onChange={(item) => {
                  if (item) {
                    setPrefixNumberTwo(item);
                    setBkEngineDetail((prev) => {
                      return {
                        ...prev,
                        contact_phone: item.value,
                      };
                    });
                  }
                }}
              />
            </FormField>
          )}

          <FormField
            label={t("apartmentContent.secondarycontact")}
            className="w-full"
            errors={validationError}
            errorKey={"contact_phone"}
          >
            <TextInput
              disabled={
                postBookingEngineLocalDetailMutation.isLoading ||
                isLoadingApartmentBkEngineDetail
              }
              value={bkEngineDetail.contact_phone ?? ""}
              onChange={(val) => {
                setBkEngineDetail((prev) => {
                  return {
                    ...prev,
                    contact_phone: val,
                  };
                });
              }}
            />
          </FormField>
        </div>

        <FormField
          label={t("apartmentContent.secondarymail")}
          errors={validationError}
          errorKey={"contact_mail"}
          className="mb-3"
        >
          <TextInput
            type="email"
            disabled={
              postBookingEngineLocalDetailMutation.isLoading ||
              isLoadingApartmentBkEngineDetail
            }
            value={bkEngineDetail.contact_mail ?? ""}
            onChange={(val) => {
              setBkEngineDetail((prev) => {
                return {
                  ...prev,
                  contact_mail: val,
                };
              });
            }}
          />
        </FormField>
          {apartmentDetail?.results?.country_en?.toLowerCase() === 'italy' && <><hr/>
          <FormField
          label={t("apartmentContent.cinCode")}
          className="mb-3"
          errors={validationError}
          errorKey={"cin_code"}
        >
          <TextInput
            value={bkEngineDetail.cin_code ?? ""}
            disabled={
              isLoadingApartmentBkEngineDetail ||
              postBookingEngineLocalDetailMutation.isLoading
            }
            onChange={(val) => {
              setBkEngineDetail((prev) => {
                return {
                  ...prev,
                  cin_code: val,
                };
              });
            }}
          />
        </FormField>
        <FormField
          label={t("apartmentContent.cirCode")}
          className="mb-10"
          errors={validationError}
          errorKey={"cir_code"}
        >
          <TextInput
            value={bkEngineDetail.cir_code ?? ""}
            disabled={
              isLoadingApartmentBkEngineDetail ||
              postBookingEngineLocalDetailMutation.isLoading
            }
            onChange={(val) => {
              setBkEngineDetail((prev) => {
                return {
                  ...prev,
                  cir_code: val,
                };
              });
            }}
          />
        </FormField>
          </>}
        
      </div>
      <div className="mt-5">
        <Button
          label={t("general.save")}
          disabled={postBookingEngineLocalDetailMutation.isLoading}
          loading={postBookingEngineLocalDetailMutation.isLoading}
          onClick={() => {
            const newObj = {
              cin_code:
                bkEngineDetail.cin_code === "" ? null : bkEngineDetail.cin_code,
              cir_code:
                bkEngineDetail.cir_code === "" ? null : bkEngineDetail.cir_code,
              main_contact_phone:
                bkEngineDetail.main_contact_phone === ""
                  ? null
                  : bkEngineDetail.main_contact_phone,
              main_contact_mail:
                bkEngineDetail.main_contact_mail === ""
                  ? null
                  : bkEngineDetail.main_contact_mail,
              contact_phone:
                bkEngineDetail.contact_phone === ""
                  ? null
                  : bkEngineDetail.contact_phone,
              contact_mail:
                bkEngineDetail.contact_mail === ""
                  ? null
                  : bkEngineDetail.contact_mail,
              /* local_key: id,
              user_key: profile?.user_key, */
            };
            const { error } = bkengineContactSchema.validate(newObj);
            if (error) {
              setValidationError(error);
              toast.error(t("general.invalidFields"));
            } else {
              setValidationError(null);
              if (bkEngineDetail.main_contact_phone?.includes("+")) {
                if (
                  bkEngineDetail.contact_phone &&
                  bkEngineDetail.contact_phone !== "" &&
                  bkEngineDetail.contact_phone?.includes("+")
                ) {
                  postBookingEngineLocalDetailMutation.mutate({
                    ...newObj,
                    local_key: id,
                    user_key: profile?.user_key,
                  });
                } else if (
                  !bkEngineDetail.contact_phone ||
                  bkEngineDetail.contact_phone === ""
                ) {
                  postBookingEngineLocalDetailMutation.mutate({
                    ...newObj,
                    local_key: id,
                    user_key: profile?.user_key,
                  });
                } else {
                  toast.error(t("general.selectPrefixNumber"));
                }
              } else {
                toast.error(t("general.selectPrefixNumber"));
              }
            }
          }}
        />
      </div>
    </>
  );
};

export default BkContacts;
