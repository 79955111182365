import dayjs from 'dayjs';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../components/Button';
import PageTitle from '../components/PageTitle';
import AddCreditCard from '../components/payment/AddCreditCard';
import AddSepaIban from '../components/payment/AddSepaIban';
import EmptyState from '../components/EmptyState';
import {
  fetchDirectLocals,
  fetchPaymentMethod,
  forcePayment,
  storePaymentMethod,
} from '../shared/queries';
import { Local, PaymentMethod } from '../types';
import { useTranslation } from 'react-i18next';

export default function PaymentMethods() {
  const { t } = useTranslation();

  const history = useHistory();

  const { data, refetch, isFetching } = useQuery(
    'paymentMethods',
    fetchPaymentMethod
  );

  const { data: locals } = useQuery('localsList', fetchDirectLocals);

  const [addCard, setAddCard] = useState(false);
  const [addIban, setAddIban] = useState(false);

  function getConnectedApartments(method: PaymentMethod): Local[] {
    if (locals) {
      const data = locals.results.filter((l) => {
        return l.stripe_pm_id === method.id;
      });
      return data;
    }
    return [];
  }

  const storeSourceMutation = useMutation(
    (data: any) => storePaymentMethod(data.type, data.data.id),
    {
      onSuccess: () => {
        setAddCard(false);
        setAddIban(false);
        refetch();
        toast.success(t('general.created'));
      },
      onError: () => {
        toast.error(t('general.requestError'));
      },
    }
  );
  
  const {mutate: forcePaymentMutation, isLoading: isLoadingForcePayment} = useMutation(() => forcePayment(), {
    onSuccess: () => {
      toast.success(t('general.requestCompleted'));
    },
    onError: (error: Error) => {
      toast.error(error.message || t('general.requestError'));
    },
  });

  return (
    <div>
      <PageTitle title={t('paymentMethods.paymentMethods')}></PageTitle>

      {data && data.results && data.results.length === 0 && isFetching && (
        <EmptyState
          title={t('paymentMethods.noPaymentMethods')}
          subtitle={t('paymentMethods.paymentMethodsText')}
          image={'/money.svg'}
        />
      )}
      <div className="flex flex-col lg:flex-row space-x-2 mb-8">
        <div className={'flex flex-col lg:flex-row lg:space-x-2'}>
          <div>
            <Button
              onClick={() => setAddCard(true)}
              className={'lg:mt-6'}
              label={t('paymentMethods.addCard')}
            />
          </div>
          <div>
            <Button
              onClick={() => setAddIban(true)}
              className={'mt-6'}
              label={t('paymentMethods.addIban')}
            />
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              history.push('/configuration/payment-methods/associate');
            }}
            className={'mt-6'}
            flat
            label={t('paymentMethods.associateApartments')}
          />
        </div>
        <div>
          <Button
            disabled={isLoadingForcePayment}
            onClick={() => {
              forcePaymentMutation();
            }}
            className={'mt-6'}
            label={t('paymentMethods.forcePayment')}
          />
        </div>
      </div>
      {data &&
        data.results.map((method, index) => (
          <div
            key={index}
            className="flex flex-col lg:flex-row border-b pb-4 lg:items-center justify-between"
          >
            {method.card && (
              <div className="flex items-center space-x-4 mt-4">
                <div>
                  <img
                    className={'h-10'}
                    src={'/images/creditcards/' + method.card.brand + '.png'}
                  />
                </div>
                <div>
                  <div>
                    <span className={'hidden lg:inline-block'}>
                      •••• •••• ••••
                    </span>{' '}
                    <span className={'inline-block md:hidden'}>••••</span>{' '}
                    {method.card.last4}
                  </div>
                </div>
                <div className={'text-gray-500 flex-1'}>
                  {t('paymentMethods.expiresAt')} {method.card.exp_month}/
                  {method.card.exp_year}
                </div>
                <div className={'text-gray-500 hidden xl:block'}>
                  {t('paymentMethods.addedAt')}{' '}
                  {dayjs.unix(method.created).format('DD-MM-YYYY')}
                </div>
              </div>
            )}

            {method.paypal && (
              <div className="flex items-center space-x-4 mt-4">
                <div>
                  <img
                    className={'h-10'}
                    src={'/images/creditcards/' + method.type + '.png'}
                  />
                </div>
                <div>
                  <div>
                    {method.paypal.payer_email}
                  </div>
                </div>
                <div className={'text-gray-500 hidden xl:block'}>
                  {t('paymentMethods.addedAt')}{' '}
                  {dayjs.unix(method.created).format('DD-MM-YYYY')}
                </div>
              </div>
            )}

            {method.sepa_debit && (
              <div className="flex mt-4 items-center space-x-4">
                <div>
                  <img className={'h-10'} src="/images/creditcards/sepa.png" />
                </div>
                <div>
                  <div>
                    <span className={'hidden lg:inline-block'}>
                      •••• •••• ••••
                    </span>{' '}
                    <span className={'inline-block md:hidden'}>••••</span>{' '}
                    {method.sepa_debit.last4}
                  </div>
                </div>
                <div
                  className={
                    'text-gray-500 text-ellipsis overflow-hidden flex-1'
                  }
                >
                  <a href={method.sepa_debit.mandate_url} target={'_blank'}>
                    Visualizza il mandato
                  </a>
                </div>
                <div className={'text-gray-500 hidden xl:block'}>
                  {t('paymentMethods.addedAt')}{' '}
                  {dayjs.unix(method.created).format('DD-MM-YYYY')}
                </div>
              </div>
            )}

            <div className={'font-medium mt-4'}>
              {t('paymentMethods.associatedApartments', {
                connected: getConnectedApartments(method).length,
                total: locals?.results.length,
              })}
            </div>
          </div>
        ))}

      <AddSepaIban
        visible={addIban}
        onClose={() => setAddIban(false)}
        onSave={() => {
          setAddIban(false);
          refetch();
        }}
        onSource={(data: any) => {
          storeSourceMutation.mutate({
            data: {id: data.payment_method},
            type: 'bank',
          });
        }}
      />

      <AddCreditCard
        visible={addCard}
        onClose={() => setAddCard(false)}
        onSource={(data: any) => {
          storeSourceMutation.mutate({
            data: data,
            type: 'card',
          });
        }}
        onSave={() => {
          setAddCard(false);
          refetch();
        }}
      />
    </div>
  );
}
