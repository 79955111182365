import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { fetchPermissions, storePermissions } from "../../shared/queries";
import { BaseSelectItem, Permission } from "../../types";
import SelectInput from "../SelectInput";
import { useTranslation } from "react-i18next";
import Pagination from "../Pagination";
import Icon from "../Icon";
import Button from "../Button";
import { usePopper } from "react-popper";
import { motion } from "framer-motion";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import PageWrapper from "../PageWrapper";
import ModalActions from "../ModalAction";

type ApartmentPermissionProps = {
  user_key: string;
};

export default function ApartmentPermission({
  user_key,
}: ApartmentPermissionProps) {
  const { t } = useTranslation();
  const limitPage: number = 5;
  const [permissions, setPermissions] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [objectAll, setObjectAll] = useState({
    access_tools: null,
    access_reservations: null,
    access_billing: null,
    access_invoice: null,
    access_property: null,
    access_custom: null,
    access_domotics: null,
    access_channel_manager: null,
    access_integrations: null,
    access_booking_engine: null
  });
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [channelMsgDisplay, setChannelMsgDisplay] = useState<boolean>(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const yesNoList = [
    {
      label: t("general.yes"),
      value: 2,
    },
    {
      label: t("general.no"),
      value: 0,
    },
  ];
  const channelWriteNoList = [
    {
      label: t("apartmentPermission.write"),
      value: 2,
    },
    {
      label: t("general.no"),
      value: 0,
    },
  ];

  const readWriteList = [
    {
      label: t("general.no"),
      value: 0,
    },
    {
      label: t("apartmentPermission.read"),
      value: 1,
    },
    {
      label: t("apartmentPermission.write"),
      value: 2,
    },
  ];

  const { data, isLoading, refetch } = useQuery(
    [user_key, "permissions"],
    () => fetchPermissions(user_key, limitPage, currentPage, searchValue),
    {
      initialData: {
        msg: "",
        results: {
          data: [],
          limit: limitPage,
          page: currentPage,
          tot: 0,
        },
      },
    }
  );

  // useEffect(() => {
  //   if (data && data.results) {
  //     setPageData(data.results.data.slice((currentPage - 1) * 15, 15 * currentPage));
  //   }
  // }, [data, currentPage]);

  const mutation = useMutation(
    (permission: Permission | any) => storePermissions(permission),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  useEffect(() => {
    if (data) {
      setPermissions(data.results.data);
    }

    return () => {
      setPermissions([]);
    };
  }, [data, searchValue]);

  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue]);

  return (
    <>
      <div className="text-right">
        <Button
          size="small"
          padding="sm"
          refs={setReferenceElement}
          onClick={() => {
            setVisible((prev) => !prev);
            setObjectAll({
              access_tools: null,
              access_reservations: null,
              access_billing: null,
              access_invoice: null,
              access_property: null,
              access_custom: null,
              access_domotics: null,
              access_channel_manager: null,
              access_integrations: null,
              access_booking_engine: null
            });
          }}
        >
          {t('apartmentPermission.filtroSubaccount')}
        </Button>
      </div>
      <div>
        {visible && (
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={"z-40"}
          >
            <motion.div
              transition={{
                duration: 0.1,
              }}
              initial={{
                scale: 0.8,
              }}
              animate={{
                scale: 1,
              }}
            >
              <div
                className={
                  "m-1 w-80 bg-white border rounded-2xl shadow-lg overflow-hidden"
                }
              >
                <div
                  className={
                    "flex p-2 bg-gray-50 border-b justify-between items-center"
                  }
                >
                  <div className={"font-medium text-sm"}>
                    {t("module.enableServicesApartments")}
                  </div>
                  <div className="flex">
                    <button
                      onClick={() => {
                        const o = Object.fromEntries(
                          Object.entries(objectAll).filter(
                            ([_, v]) => v != null
                          )
                        );
                        if (Object.keys(o).length > 0) {
                          const newObj = {...o, child_user_key: user_key}
                          mutation.mutate(newObj);
                        }
                        setVisible(false);
                        setObjectAll({
                          access_tools: null,
                          access_reservations: null,
                          access_billing: null,
                          access_invoice: null,
                          access_property: null,
                          access_custom: null,
                          access_domotics: null,
                          access_channel_manager: null,
                          access_integrations: null,
                          access_booking_engine: null
                        });
                      }}
                      className={
                        "bg-white flex text-cyan-600 items-center border focus:outline-none px-3 text-sm font-medium py-1 rounded-2xl"
                      }
                    >
                      {t("general.done")}
                    </button>
                  </div>
                </div>
                <div className="overflow-y-auto h-96">
                  <div className={"p-2 py-1 flex justify-between border-b"}>
                    <div className="text-sm bold w-32">
                      {t("apartmentPermission.openingAccess")}
                    </div>
                    <div className={"w-32"}>
                      <SelectInput<BaseSelectItem, false>
                        value={yesNoList.find(
                          (val) => val.value === objectAll.access_tools
                        )}
                        // smenuPlacement={"top"}
                        menuShouldScrollIntoView={false}
                        isLoading={mutation.isLoading}
                        options={yesNoList}
                        onChange={(item) => {
                          if (item) {
                            setObjectAll((prev) => {
                              return {
                                ...prev,
                                access_tools: item.value,
                              };
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={"p-2 py-1 flex justify-between border-b"}>
                    <div className="text-sm bold w-32">
                      {t("apartmentPermission.reservationAccess")}
                    </div>
                    <div className={"w-32"}>
                      <SelectInput<BaseSelectItem, false>
                        value={readWriteList.find(
                          (val) => val.value === objectAll.access_reservations
                        )}
                        // smenuPlacement={"top"}
                        menuShouldScrollIntoView={false}
                        isLoading={mutation.isLoading}
                        options={readWriteList}
                        onChange={(item) => {
                          if (item) {
                            setObjectAll((prev) => {
                              return {
                                ...prev,
                                access_reservations: item.value,
                              };
                            });
                            //access_reservations
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className={"p-2 py-1 flex justify-between border-b"}>
                    <div className="text-sm bold w-32">
                      {t("apartmentPermission.paymentAccess")}
                    </div>
                    <div className={"w-32"}>
                      <SelectInput<BaseSelectItem, false>
                        value={yesNoList.find(
                          (val) => val.value === objectAll.access_billing
                        )}
                        // smenuPlacement={"top"}
                        menuShouldScrollIntoView={false}
                        isLoading={mutation.isLoading}
                        options={yesNoList}
                        onChange={(item) => {
                          if (item) {
                            // access_billing
                            setObjectAll((prev) => {
                              return {
                                ...prev,
                                access_billing: item.value,
                              };
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={"p-2 py-1 flex justify-between border-b"}>
                    <div className="text-sm bold w-32">
                      {t("apartmentPermission.billingAccess")}
                    </div>
                    <div className={"w-32"}>
                      <SelectInput<BaseSelectItem, false>
                        value={yesNoList.find(
                          (val) => val.value === objectAll.access_invoice
                        )}
                        // smenuPlacement={"top"}
                        menuShouldScrollIntoView={false}
                        isLoading={mutation.isLoading}
                        options={yesNoList}
                        onChange={(item) => {
                          if (item) {
                            // access_invoice
                            setObjectAll((prev) => {
                              return {
                                ...prev,
                                access_invoice: item.value,
                              };
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={"p-2 py-1 flex justify-between border-b"}>
                    <div className="text-sm bold w-32">
                      {t("apartmentPermission.apartmentDetails")}
                    </div>
                    <div className={"w-32"}>
                      <SelectInput<BaseSelectItem, false>
                        // smenuPlacement={"top"}
                        value={readWriteList.find(
                          (val) => val.value === objectAll.access_property
                        )}
                        menuShouldScrollIntoView={false}
                        isLoading={mutation.isLoading}
                        options={readWriteList}
                        onChange={(item) => {
                          if (item) {
                            // access_property
                            setObjectAll((prev) => {
                              return {
                                ...prev,
                                access_property: item.value,
                              };
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={"p-2 py-1 flex justify-between border-b"}>
                    <div className="text-sm bold w-32">
                      {t("apartmentPermission.accountCustomization")}
                    </div>
                    <div className={"w-32"}>
                      <SelectInput<BaseSelectItem, false>
                        value={readWriteList.find(
                          (val) => val.value === objectAll.access_custom
                        )}
                        // smenuPlacement={"top"}
                        menuShouldScrollIntoView={false}
                        isLoading={mutation.isLoading}
                        options={readWriteList}
                        onChange={(item) => {
                          if (item) {
                            // access_custom
                            setObjectAll((prev) => {
                              return {
                                ...prev,
                                access_custom: item.value,
                              };
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={"p-2 py-1 flex justify-between border-b"}>
                    <div className="text-sm bold w-32">
                      {t("apartmentPermission.accessDomotics")}
                    </div>
                    <div className={"w-32"}>
                      <SelectInput<BaseSelectItem, false>
                        value={readWriteList.find(
                          (val) => val.value === objectAll.access_domotics
                        )}
                        // smenuPlacement={"top"}
                        menuShouldScrollIntoView={false}
                        isLoading={mutation.isLoading}
                        options={readWriteList}
                        onChange={(item) => {
                          if (item) {
                            //access_domotics
                            setObjectAll((prev) => {
                              return {
                                ...prev,
                                access_domotics: item.value,
                              };
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={"p-2 py-1 flex justify-between border-b"}>
                    <div className="text-sm bold w-32">
                      {t("apartmentPermission.access_integrations")}
                    </div>
                    <div className={"w-32"}>
                      <SelectInput<BaseSelectItem, false>
                        value={yesNoList.find(
                          (val) => val.value === objectAll.access_integrations
                        )}
                        // smenuPlacement={"top"}
                        menuShouldScrollIntoView={false}
                        isLoading={mutation.isLoading}
                        options={yesNoList}
                        onChange={(item) => {
                          if (item) {
                            //access_integrations
                            setObjectAll((prev) => {
                              return {
                                ...prev,
                                access_integrations: item.value,
                              };
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={"p-2 py-1 border-b"}>
                    <div className="flex justify-between">
                      <div className="text-sm bold w-32">
                        {t("apartmentPermission.access_channel_manager")}
                      </div>
                      <div className={"w-32"}>
                        <SelectInput<BaseSelectItem, false>
                          value={channelWriteNoList.find(
                            (val) =>
                              val.value === objectAll.access_channel_manager
                          )}
                          isDisabled
                          // smenuPlacement={"top"}
                          menuShouldScrollIntoView={false}
                          isLoading={mutation.isLoading}
                          options={channelWriteNoList}
                          onChange={(item) => {
                            if (item) {
                              //access_channel_manager
                              setChannelMsgDisplay(true);
                              setObjectAll((prev) => {
                                return {
                                  ...prev,
                                  access_channel_manager: item.value,
                                };
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="text-right">
                      <Button
                        size="small"
                        className="w-32"
                        icon="pencil"
                        iconSize="12px"
                        padding="xs"
                        label={t("general.edit")}
                        onClick={() => setChannelMsgDisplay(true)}
                      />
                    </div>
                  </div>
                  <div className={"p-2 py-1 border-b"}>
                    <div className="flex justify-between">
                      <div className="text-sm bold w-32">
                        {t("apartmentPermission.access_booking_engine")}
                      </div>
                      <div className={"w-32"}>
                        <SelectInput<BaseSelectItem, false>
                          value={channelWriteNoList.find(
                            (val) =>
                              val.value === objectAll.access_booking_engine
                          )}
                          isDisabled
                          // smenuPlacement={"top"}
                          menuShouldScrollIntoView={false}
                          isLoading={mutation.isLoading}
                          options={channelWriteNoList}
                          onChange={(item) => {
                            if (item) {
                              //access_booking_engine
                              setChannelMsgDisplay(true);
                              setObjectAll((prev) => {
                                return {
                                  ...prev,
                                  access_booking_engine: item.value,
                                };
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="text-right">
                      <Button
                        size="small"
                        className="w-32"
                        icon="pencil"
                        iconSize="12px"
                        padding="xs"
                        label={t("general.edit")}
                        onClick={() => setChannelMsgDisplay(true)}
                      />
                    </div>
                  </div>

                  <div className="h-20"></div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </div>
      <div className="relative bg-white rounded-xl border-gray-300 border h-8 my-3 md:w-64">
        <input
          style={{
            height: "100%",
            width: "100%",
          }}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setCurrentPage(1);
          }}
          placeholder={t("general.searchForApartment")}
          className={
            "relative flex items-center bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded-2xl truncate"
          }
        />
        <Icon
          name={"home"}
          size={"18px"}
          className={"absolute top-1 right-1"}
        />
      </div>
      {!isLoading &&
        permissions &&
        permissions.map((permission: Permission) => (
          <div
            className={"flex flex-col w-full space-y-2"}
            key={permission.local.name}
          >
            <div className={"w-44 text-sm font-medium text-cyan-600 mt-4"}>
              {permission.local.name}
            </div>
            <div className="border-b border-cyan-300 h-full my-3"></div>

            <div
              className={"items-center justify-between flex flex-1 space-x-3"}
            >
              <div className={"block"}>
                {t("apartmentPermission.openingAccess")}
              </div>
              <div className={"w-32"}>
                <SelectInput<BaseSelectItem, false>
                  value={yesNoList.find(
                    (v) => v.value === permission.access_tools
                  )}
                  menuPlacement={"top"}
                  menuShouldScrollIntoView={false}
                  isLoading={mutation.isLoading}
                  options={yesNoList}
                  onChange={(item) => {
                    if (item) {
                      setPermissions(
                        permissions.map((p: Permission) => {
                          if (p.id === permission.id) {
                            const newState = {
                              ...p,
                              access_tools: item.value,
                            };
                            mutation.mutate(newState);
                            return newState;
                          }
                          return p;
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>

            <div
              className={"items-center justify-between flex flex-1 space-x-3"}
            >
              <div className={"block"}>
                {t("apartmentPermission.reservationAccess")}
              </div>
              <div className={"w-32"}>
                <SelectInput<BaseSelectItem, false>
                  value={readWriteList.find(
                    (v) => v.value === permission.access_reservations
                  )}
                  // menuShouldScrollIntoView={false}
                  menuPlacement={"top"}
                  isLoading={mutation.isLoading}
                  options={readWriteList}
                  onChange={(item) => {
                    if (item) {
                      setPermissions(
                        permissions.map((p: Permission) => {
                          if (p.id === permission.id) {
                            const newState = {
                              ...p,
                              access_reservations: item.value,
                            };
                            mutation.mutate(newState);
                            return newState;
                          }
                          return p;
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>

            <div
              className={"items-center justify-between flex flex-1 space-x-3"}
            >
              <div className={"block"}>
                {t("apartmentPermission.paymentAccess")}
              </div>
              <div className={"w-32"}>
                <SelectInput<BaseSelectItem, false>
                  value={yesNoList.find(
                    (v) => v.value === permission.access_billing
                  )}
                  menuShouldScrollIntoView={false}
                  menuPlacement={"top"}
                  isLoading={mutation.isLoading}
                  options={yesNoList}
                  onChange={(item) => {
                    if (item) {
                      setPermissions(
                        permissions.map((p: Permission) => {
                          if (p.id === permission.id) {
                            const newState = {
                              ...p,
                              access_billing: item.value,
                            };
                            mutation.mutate(newState);
                            return newState;
                          }
                          return p;
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>

            <div
              className={"items-center justify-between flex flex-1 space-x-3"}
            >
              <div className={"block"}>
                {t("apartmentPermission.billingAccess")}
              </div>
              <div className="w-32">
                <SelectInput<BaseSelectItem, false>
                  value={yesNoList.find(
                    (v) => v.value === permission.access_invoice
                  )}
                  menuPlacement={"top"}
                  menuShouldScrollIntoView={false}
                  isLoading={mutation.isLoading}
                  options={yesNoList}
                  onChange={(item) => {
                    if (item) {
                      setPermissions(
                        permissions.map((p: Permission) => {
                          if (p.id === permission.id) {
                            const newState = {
                              ...p,
                              access_invoice: item.value,
                            };
                            mutation.mutate(newState);
                            return newState;
                          }
                          return p;
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>

            <div
              className={"items-center justify-between flex flex-1 space-x-3"}
            >
              <div className={"block"}>
                {t("apartmentPermission.apartmentDetails")}
              </div>
              <div className="w-32">
                <SelectInput<BaseSelectItem, false>
                  value={readWriteList.find(
                    (v) => v.value === permission.access_property
                  )}
                  menuPlacement={"top"}
                  menuShouldScrollIntoView={false}
                  isLoading={mutation.isLoading}
                  options={readWriteList}
                  onChange={(item) => {
                    if (item) {
                      setPermissions(
                        permissions.map((p: Permission) => {
                          if (p.id === permission.id) {
                            const newState = {
                              ...p,
                              access_property: item.value,
                            };
                            mutation.mutate(newState);
                            return newState;
                          }
                          return p;
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>

            <div
              className={"items-center justify-between flex flex-1 space-x-3"}
            >
              <div className={"block"}>
                {t("apartmentPermission.accountCustomization")}
              </div>
              <div className=" w-32">
                <SelectInput<BaseSelectItem, false>
                  value={readWriteList.find(
                    (v) => v.value === permission.access_custom
                  )}
                  menuPlacement={"top"}
                  menuShouldScrollIntoView={false}
                  isLoading={mutation.isLoading}
                  options={readWriteList}
                  onChange={(item) => {
                    if (item) {
                      setPermissions(
                        permissions.map((p: Permission) => {
                          if (p.id === permission.id) {
                            const newState = {
                              ...p,
                              access_custom: item.value,
                            };
                            mutation.mutate(newState);
                            return newState;
                          }
                          return p;
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>

            <div
              className={"items-center justify-between flex flex-1 space-x-3"}
            >
              <div className={"block"}>
                {t("apartmentPermission.accessDomotics")}
              </div>
              <div className="w-32">
                <SelectInput<BaseSelectItem, false>
                  value={readWriteList.find(
                    (v) => v.value === permission.access_domotics
                  )}
                  menuPlacement={"top"}
                  menuShouldScrollIntoView={false}
                  isLoading={mutation.isLoading}
                  options={readWriteList}
                  onChange={(item) => {
                    if (item) {
                      setPermissions(
                        permissions.map((p: Permission) => {
                          if (p.id === permission.id) {
                            const newState = {
                              ...p,
                              access_domotics: item.value,
                            };
                            mutation.mutate(newState);
                            return newState;
                          }
                          return p;
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>
            <div
              className={"items-center justify-between flex flex-1 space-x-3"}
            >
              <div className={"block"}>
                {t("apartmentPermission.access_integrations")}
              </div>
              <div className=" w-32">
                <SelectInput<BaseSelectItem, false>
                  value={readWriteList.find(
                    (v) => v.value === permission.access_integrations
                  )}
                  menuPlacement={"top"}
                  menuShouldScrollIntoView={false}
                  isLoading={mutation.isLoading}
                  options={readWriteList}
                  onChange={(item) => {
                    if (item) {
                      setPermissions(
                        permissions.map((p: Permission) => {
                          if (p.id === permission.id) {
                            const newState = {
                              ...p,
                              access_integrations: item.value,
                            };
                            mutation.mutate(newState);
                            return newState;
                          }
                          return p;
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>
            <div
              className={"items-center justify-between flex flex-1 space-x-3"}
            >
              <div className={"block"}>
                {t("apartmentPermission.access_channel_manager")}
              </div>
              <div className=" w-32">
                <SelectInput<BaseSelectItem, false>
                  value={channelWriteNoList.find(
                    (v) => v.value === permission.access_channel_manager
                  )}
                  isDisabled
                  menuPlacement={"top"}
                  menuShouldScrollIntoView={false}
                  isLoading={mutation.isLoading}
                  options={channelWriteNoList}
                  onChange={(item) => {
                    if (item) {
                      setPermissions(
                        permissions.map((p: Permission) => {
                          if (p.id === permission.id) {
                            const newState = {
                              ...p,
                              access_channel_manager: item.value,
                            };
                            mutation.mutate(newState);
                            return newState;
                          }
                          return p;
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>
            <div
              className={"items-center justify-between flex flex-1 space-x-3"}
            >
              <div className={"block"}>
                {t("apartmentPermission.access_booking_engine")}
              </div>
              <div className=" w-32">
                <SelectInput<BaseSelectItem, false>
                  value={channelWriteNoList.find(
                    (v) => v.value === permission.access_booking_engine
                  )}
                  isDisabled
                  menuPlacement={"top"}
                  menuShouldScrollIntoView={false}
                  isLoading={mutation.isLoading}
                  options={channelWriteNoList}
                  onChange={(item) => {
                    if (item) {
                      setPermissions(
                        permissions.map((p: Permission) => {
                          if (p.id === permission.id) {
                            const newState = {
                              ...p,
                              access_booking_engine: item.value,
                            };
                            mutation.mutate(newState);
                            return newState;
                          }
                          return p;
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      {!isLoading && permissions && permissions.length > 1 ? (
        <div className="mt-5">
          <Pagination
            page={currentPage}
            pageSize={limitPage}
            totalItems={+data?.results?.tot}
            onPageUpdate={(value) => {
              if (value === "+") {
                setCurrentPage((prev) => prev + 1);
                return;
              }
              if (value === "-") {
                setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
                return;
              }
              setCurrentPage(+value);
            }}
          />
        </div>
      ) : null}
      <Modal visible={channelMsgDisplay}>
        <ModalCard title={t("apartments.warning")} className="w-1/2">
          <ModalSection>
            <PageWrapper>
              <p>{t("apartmentPermission.channel_manager_warning")}</p>
              <div className="w-full mt-3">
              <p>{t("apartmentPermission.access_channel_manager")}</p>

                <SelectInput<BaseSelectItem, false>
                  value={channelWriteNoList.find(
                    (val) => val.value === objectAll.access_channel_manager
                  )}
                  // smenuPlacement={"top"}
                  menuPosition="fixed"
                  menuShouldScrollIntoView={false}
                  isLoading={mutation.isLoading}
                  options={channelWriteNoList}
                  onChange={(item) => {
                    if (item) {
                      //access_channel_manager
                      setChannelMsgDisplay(true);
                      setObjectAll((prev) => {
                        return {
                          ...prev,
                          access_channel_manager: item.value,
                        };
                      });
                    }
                  }}
                />
              </div>
              <div className="w-full mt-3">
                <p>{t("apartmentPermission.access_booking_engine")}</p>
                <SelectInput<BaseSelectItem, false>
                  value={channelWriteNoList.find(
                    (val) => val.value === objectAll.access_booking_engine
                  )}
                  menuPosition="fixed"

                  // smenuPlacement={"top"}
                  menuShouldScrollIntoView={false}
                  isLoading={mutation.isLoading}
                  options={channelWriteNoList}
                  onChange={(item) => {
                    if (item) {
                      //access_booking_engine
                      setChannelMsgDisplay(true);
                      setObjectAll((prev) => {
                        return {
                          ...prev,
                          access_booking_engine: item.value,
                        };
                      });
                    }
                  }}
                />
              </div>
            </PageWrapper>
            <ModalActions
              onClose={() => {
                setChannelMsgDisplay(false);
              }}
              closeLabel={t("general.close")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
    </>
  );
}
