import { useState } from "react";
import Icon from "../Icon";
import Modal from "../Modal";
import ModalSection from "../ModalSection";
import ModalCard from "../ModalCard";
import ModalAction from "../ModalAction";
import ExportBuroIta from "./ExportBuroIta";
import ExportBuroSpain from "./ExportBuroSpain";
import ExportBuroIstat from "./ExportBuroIstat";
import ExportRegistry from "./ExportRegistry";
import ExportReservations from "./ExportReservations";
import { useMutation } from "react-query";
import { fetchReservationAction } from "../../shared/queries";
import { useTranslation } from "react-i18next";
import ExportBuroPayTourist from "./ExportBuroPayTourist";
import ExportBuroTicino from "./ExportBuroTicino";

export default function ReservationExport() {
  const { t } = useTranslation();

  const [currentExport, setCurrentExport] = useState<string>();
  const [currentData, setCurrentData] = useState<any>();

  const downloadMutation = useMutation(() =>
    fetchReservationAction(currentData)
  );

  return (
    <div className={"p-4"}>
      <div className="flex space-x-6 items-center">
        <img
          src={"/export.svg"}
          alt="export-icon"
          className={"h-24 inline-block"}
        />
        <div>
          <div className={"text-lg font-semibold"}>
            {t("reservationExport.exportData")}
          </div>
          <div className={"text-gray-600"}>
            {t("reservationExport.exportDataText")}
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-10 space-y-2">
        <button
          onClick={() => setCurrentExport("alloggiati")}
          className="flex justift-between items-center p-4 bg-cyan-50 hover:bg-cyan-100 transition-all cursor-pointer font-medium text-cyan-800 rounded-2xl"
        >
          <div className={"text-left flex-1"}>Portale alloggiati</div>
          <div>
            <Icon name={"document-download"} size={"24px"} className={"mr-2"} />
          </div>
        </button>

        <button
          onClick={() => setCurrentExport("mossos")}
          className="flex justift-between items-center p-4 bg-cyan-50 hover:bg-cyan-100 transition-all cursor-pointer font-medium text-cyan-800 rounded-2xl"
        >
          <div className={"text-left flex-1"}>
            {t("reservationExport.spain")}
          </div>
          <div>
            <Icon name={"document-download"} size={"24px"} className={"mr-2"} />
          </div>
        </button>

        <button
          onClick={() => setCurrentExport("istat")}
          className="flex justift-between items-center p-4 bg-cyan-50 hover:bg-cyan-100 transition-all cursor-pointer font-medium text-cyan-800 rounded-2xl"
        >
          <div className={"text-left flex-1"}>ISTAT</div>
          <div>
            <Icon name={"document-download"} size={"24px"} className={"mr-2"} />
          </div>
        </button>

        <button
          onClick={() => setCurrentExport("registry")}
          className="flex justift-between items-center p-4 bg-cyan-50 hover:bg-cyan-100 transition-all cursor-pointer font-medium text-cyan-800 rounded-2xl"
        >
          <div className={"text-left flex-1"}>
            {t("reservationExport.guestRegistry")}
          </div>
          <div>
            <Icon name={"document-download"} size={"24px"} className={"mr-2"} />
          </div>
        </button>

        <button
          onClick={() => setCurrentExport("reservations")}
          className="flex justift-between items-center p-4 bg-cyan-50 hover:bg-cyan-100 transition-all cursor-pointer font-medium text-cyan-800 rounded-2xl"
        >
          <div className={"text-left flex-1"}>
            {t("reservationExport.reservationList")}
          </div>
          <div>
            <Icon name={"document-download"} size={"24px"} className={"mr-2"} />
          </div>
        </button>

        <button
          onClick={() => setCurrentExport("PayTourist")}
          className="flex justift-between items-center p-4 bg-cyan-50 hover:bg-cyan-100 transition-all cursor-pointer font-medium text-cyan-800 rounded-2xl"
        >
          <div className={"text-left flex-1"}>PayTourist</div>
          <div>
            <Icon name={"document-download"} size={"24px"} className={"mr-2"} />
          </div>
        </button>

        <button
          onClick={() => setCurrentExport("BuroTicino")}
          className="flex justift-between items-center p-4 bg-cyan-50 hover:bg-cyan-100 transition-all cursor-pointer font-medium text-cyan-800 rounded-2xl"
        >
          <div className={"text-left flex-1"}>Ticino</div>
          <div>
            <Icon name={"document-download"} size={"24px"} className={"mr-2"} />
          </div>
        </button>
      </div>

      <Modal visible={currentExport !== undefined}>
        <ModalCard className={"w-full max-w-lg"}>
          <ModalSection className={"w-full max-w-xl"}>
            {currentExport === "alloggiati" && (
              <ExportBuroIta onChange={(data) => setCurrentData(data)} />
            )}

            {currentExport === "PayTourist" && (
              <ExportBuroPayTourist onChange={(data) => setCurrentData(data)} />
            )}

            {currentExport === "BuroTicino" && (
              <ExportBuroTicino onChange={(data) => setCurrentData(data)} />
            )}

            {currentExport === "mossos" && (
              <ExportBuroSpain onChange={(data) => setCurrentData(data)} />
            )}

            {currentExport === "istat" && (
              <ExportBuroIstat onChange={(data) => setCurrentData(data)} />
            )}

            {currentExport === "registry" && (
              <ExportRegistry onChange={(data) => setCurrentData(data)} />
            )}

            {currentExport === "reservations" && (
              <ExportReservations onChange={(data) => setCurrentData(data)} />
            )}
          </ModalSection>
          <ModalAction
            closeLabel={t("general.cancel")}
            saveLabel={t("general.export")}
            isLoading={downloadMutation.isLoading}
            onSave={() => {
              downloadMutation.mutate();
            }}
            onClose={() => {
              setCurrentExport(undefined);
            }}
          />
        </ModalCard>
      </Modal>
    </div>
  );
}
