import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DomainContext } from "../DomainContext";
import MenuItem from "./MenuItem";
import { useQuery } from "react-query";
import { fetchAllModules } from "../shared/queries";
import { moduleId } from "../shared/utils/config";
import { RootState } from "../reducers";
import { useDispatch, useSelector } from "react-redux";
import { fetchThreads } from "../features/messaging/messagingSlice";
import store from "../store";

export default function NavigationMenu({
  isMini = false,
}: {
  isMini: boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { profile } = useSelector((state: RootState) => state.login);

  const { appType } = useContext(DomainContext);
  const [channelManager, setChannelManager] = useState(false);
  const dispatch = useDispatch();

  const { currentFilter, has_unread_messages, limit, query } = useSelector(
    (state) => store.getState().messageStore
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>(["navigationMenu"], () => {
    return fetchAllModules().then((data: any) => {
      const moduleFinded = data?.modules?.find(
        (module: any) => module?.id === moduleId.channel_manager
      );
      if (moduleFinded) {
        setChannelManager(true);
      } else {
        setChannelManager(false);
      }
      return data;
    });
  });
  useEffect(() => {
    dispatch(
      fetchThreads({
        limit,
        filter: currentFilter,
        topic: `message_channel/${profile?.user_key}`,
        query,
      })
    );
  }, [dispatch, currentFilter, limit, query, profile?.user_key]);

  return (
    <div className="overflow-y-auto h-screen">
      <MenuItem
        className={"mt-6"}
        icon={"home"}
        active={history && history.location.pathname === "/dashboard"}
        mini={isMini}
        label={t("navigation.dashboard")}
        to={"/dashboard"}
      />

      <MenuItem
        icon={"bulletList"}
        label={t("navigation.modules")}
        mini={isMini}
        active={history && history.location.pathname.indexOf("modules") > -1}
        to={"/modules"}
        sided
      />

      <MenuItem
        icon={"cart"}
        label={t("navigation.services")}
        mini={isMini}
        active={
          history &&
          (history.location.pathname.indexOf("services") > -1 ||
            history.location.pathname.indexOf("orders") > -1)
        }
        to={"/orders"}
      />
      {/* <MenuItem
        icon={"shopping-bag"}
        label={t("navigation.orders")}
        mini={isMini}
        active={history && history.location.pathname.indexOf("orders") > -1}
        to={"/orders"}
      /> */}
      <MenuItem
        icon={"chip"}
        active={history && history.location.pathname === "/domotics"}
        mini={isMini}
        label={t("navigation.domotics")}
        to={"/domotics"}
      />

      <MenuItem
        icon={"support"}
        label={t("general.support")}
        to={"/support"}
        active={history && history.location.pathname.indexOf("support") > -1}
        mini={isMini}
      />

      {!isMini && (
        <div className={"text-gray-500 pl-6 text-sm font-medium mt-4"}>
          {t("navigation.management")}
        </div>
      )}

      <MenuItem
        className={"mt-4"}
        icon={"book-open"}
        label={t("navigation.reservations")}
        mini={isMini}
        active={
          history && history.location.pathname.indexOf("reservations") > -1
        }
        to={"/reservations"}
      />
      <MenuItem
        // className={'mt-4'}
        icon={"office-building"}
        label={t("navigation.apartments")}
        mini={isMini}
        active={history && history.location.pathname.indexOf("apartments") > -1}
        to={"/apartments"}
      />
      {/* {profile?.user_key !== "DV8FOzJ5cjMSI67t0000000000000000" && <MenuItem
        icon={"calendar"}
        label={t("navigation.calendar")}
        mini={isMini}
        active={history && history.location.pathname === "/calendar"}
        to={"/calendar"}
      />} */}
      {/* {(profile?.user_key === "VT1xfjPoskpbCWGj0000000000000000" ||
        profile?.user_key === "DV8FOzJ5cjMSI67t0000000000000000" ||
        profile?.user_key === "CC25u7xWn6HgHBep0000000000000000" ||
        profile?.user_key === "NC0Yh8Squms61a190000000000000000" ||
        profile?.user_key === "GD1u3XpPs2WG71Md0000000000000000" ||
        profile?.user_key === "Ls5UJIkPfHKsb9vK0000000000000000" ||
        profile?.user_key === "Ed2tOMyae9T5ABmq0000000000000000" ||
        profile?.user_key === "MR2Hw64QtwEp7Azq0000000000000000" ||
        profile?.user_key === "Ms35rEACPDAQxg6X0000000000000000" ||
        profile?.user_key === "LM117gJEXETDaaQy0000000000000000" ||
        profile?.user_key === "0XW4Y4UCOjL6Vl000000000000000000") && */}
      <MenuItem
        icon={"calendar"}
        label={t("navigation.calendar")}
        mini={isMini}
        active={history && history.location.pathname === "/calendar-new"}
        to={"/calendar-new"}
      />
      {
        <MenuItem
          icon={has_unread_messages ? "unreadmail" : "readmail"}
          label={t("navigation.messaging")}
          mini={isMini}
          active={history && history.location.pathname === "/messaging"}
          to={"/messaging"}
        />
      }
      {/* } */}
      {channelManager && (
        <MenuItem
          icon={"wrench"}
          label="Channel Manager"
          mini={isMini}
          active={history && history.location.pathname === "/channel-manager"}
          to={"/channel-manager"}
        />
      )}

      {channelManager && (
          <MenuItem
            icon={"calendar"}
            label={"Booking engine"}
            to={"/booking-engine"}
            active={
              history &&
              history.location.pathname.indexOf("booking-engine") > -1
            }
            mini={isMini}
          />
        )}

      {/* {
        !isMini &&
        <div className={'text-gray-500 pl-6 text-sm font-medium mt-4'}>
          {t('navigation.administration')}
        </div>
      } */}

      {appType === "vikey" && (
        <MenuItem
          icon={"document-text"}
          label={t("navigation.invoices")}
          to={"/invoices"}
          active={history && history.location.pathname === "/invoices"}
          mini={isMini}
        />
      )}

      <MenuItem
        className={"mt-4"}
        icon={"adjustments"}
        label={t("navigation.administration")}
        to={"/configuration/bureaucracy"}
        active={
          history && history.location.pathname.indexOf("configuration") > -1
        }
        mini={isMini}
      />
      <p
        className="text-xs md:text-left text-right text-gray-500 hover:underline cursor-pointer mt-10 md:pl-7 pr-7"
        onClick={() => {
          window.location.replace(
            "https://vikey.it/wp-content/policy-vikey/PrivacyPolicy-HOST.html"
          );
        }}
      >
        Privacy Policy
      </p>
    </div>
  );
}
