import React, { useState } from "react";
import Button from "./Button";
import TooltipModal from "./TooltipModal";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
const themeMap = {
  info: {
    bg: "bg-blue-500",
    text: "text-white",
    button: "info",
    icon: "info-circle",
  },
  alert: {
    bg: "bg-yellow-400",
    text: "text-white",
    button: "warning",
    icon: "warning",
  },
  error: {
    bg: "bg-red-500",
    text: "text-white",
    button: "danger",
    icon: "error",
  },
};

type TextBannerProps = {
  visible: boolean;
  alerts: any[];
  className?: string;
  index?: number;
  variant?: "info" | "alert" | "error";
};

export const TextBanner: React.FC<TextBannerProps> = ({
  visible,
  alerts,
  className = "",
  variant = "alert",
  index,
}) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const theme = themeMap[variant];

  const showNextAlert = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % alerts.length);
  };

  const showPreviousAlert = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + alerts.length) % alerts.length
    );
  };

  if (!visible || alerts.length === 0) return null;

  return (
    <AnimatePresence initial={false}>
      {expanded && (
        <motion.div
          layout
          initial={{ opacity: 0, height: 0, scale: 0.95 }}
          animate={{ opacity: 1, height: "auto", scale: 1 }}
          exit={{ opacity: 0, height: 0, scale: 0.95 }}
          transition={{
            duration: 0.1,
            ease: "easeInOut",
          }}
          className={`${theme.bg} ${theme.text} flex flex-col items-center overflow-hidden pb-2 ${className}`}
        >
          <div className="flex flex-wrap px-2 pt-2 items-center w-full">
            <TooltipModal
              content={t("messaging.specialActionDisclaimer")}
              strokeColor={theme.text.replace("text-", "")}
              className="mr-2"
            />

            {/* Previous Button */}
            {alerts.length > 1 && (
              <Button
                onClick={showPreviousAlert}
                icon="arrow-left"
                color="info"
              />
            )}

            {/* Message Carousel */}
            <span key={alerts[currentIndex].id} className="flex-1">
              {alerts[currentIndex].body}
            </span>
            {/*  Counter */}
            {alerts.length > 1 && (
              <motion.span
                className={`${theme.text} text-sm px-2 py-1 rounded-full bg-black/20 md:static absolute top-0 right-0`}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
              >
                <AnimatePresence>
                  <motion.span
                    key={currentIndex}
                    initial={{ x: -10, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="inline-block"
                  >
                    {currentIndex + 1}
                  </motion.span>
                </AnimatePresence>
                / {alerts.length}
              </motion.span>
            )}

            {/* Next Button */}
            {alerts.length > 1 && (
              <Button onClick={showNextAlert} icon="arrow-right" color="info" />
            )}
          </div>
        </motion.div>
      )}

      {/* Persistent toggle button */}
      <div className="w-full flex justify-center">
        <Button
          color="info"
          icon={!expanded ? "arrow-tip-down" : "arrow-tip-up"}
          onClick={() => setExpanded(!expanded)}
        />
      </div>
    </AnimatePresence>
  );
};
