import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SocketEvent, BreadItem, SocketState } from "../../types";

const socketSlice = createSlice({
    name: 'socketSlice',
    initialState: {
        events: [],
        subscriptions: [],
        eventListeners:[],
        connected: false
    } as SocketState,
    reducers: {
        setStatus: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.connected = action.payload
        },
        addEvent: (
            state,
            action: PayloadAction<SocketEvent>
        ) => {
            if (!state.events.find(e => e.data.reqid === action.payload.data.reqid)) {
                state.events.push(action.payload)
            }
        },
        clearEvents: (
            state
        ) => {
            state.events.length = 0
        },
        addEventListener: (state, action: PayloadAction<string>) => {
            if (!state.eventListeners.includes(action.payload)) {
                state.eventListeners.push(action.payload);
            }
        },
        clearEventListeners: (state) => {
            state.eventListeners = [];
        },
        addSubscription: (
            state,
            action: PayloadAction<string>
        ) => {
            state.subscriptions.push(action.payload)
        },
        clearSubscriptions: (
            state
        ) => {
            state.subscriptions.length = 0
        }
    }
})

export const {
    addEvent,
    setStatus,
    addSubscription,
    addEventListener,
    clearSubscriptions,
    clearEvents
} = socketSlice.actions

export default socketSlice.reducer