import Joi from "joi";

export default Joi.object({
  cin_code: Joi.any(),
  cir_code: Joi.any(),
  main_contact_phone: Joi.string().required().messages({
    "string.base": "Numero Principale obbligatorio",
    "string.required": "Numero Principale obbligatorio",
    "string.empty": "Numero Principale obbligatorio",
  }),
  main_contact_mail: Joi.string().required().messages({
    "string.base": "Mail principale obbligatoria",
    "string.required": "Mail principale obbligatoria",
    "string.empty": "Mail principale obbligatoria",
  }),
  contact_phone: Joi.any(),
  contact_mail: Joi.any(),
});
