import { io, Socket } from 'socket.io-client'
import { addEvent, addEventListener, addSubscription, clearSubscriptions, setStatus } from '../../features/socket/socketSlice'
import store from '../../store'
import { GenericSocketManager } from '../../types'
import { REACT_APP_VIKEY_API_URL } from './config'
import { getAuthToken } from './tokenStore'


const socketManagerV2 = {
  socket: null as Socket | null,
  old_sid:'' as string | undefined,


  init(name_space:string) {
    this.socket = io(REACT_APP_VIKEY_API_URL + '/' + name_space, {
      path: '/api/v3/socket',
      extraHeaders: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
      forceNew: true,
      reconnection: true,
    });
    this.old_sid = this.socket.id

    this.socket.on('connect', () => {
      // console.log('[SOCKET] - Connected with SID:', this.socket?.id);
      store.dispatch(setStatus(true));
      if(this.old_sid === undefined){
        this.old_sid = this.socket?.id
      }
      // this.old_sid = this.socket?.id
      // console.log("old sid ", this.old_sid)
      // console.log("old sid ", this.socket?.id)

      const subscriptions = store.getState().socket.subscriptions;
      if (subscriptions.length > 0 && this.socket?.id !== this.old_sid) {
        // console.log('[SOCKET] - Re-subscribing to topics:', subscriptions);
        this.socket?.emit('subscriptions', { topics: subscriptions });
        this.old_sid = this.socket?.id
      }
  
      const eventListeners = store.getState().socket.eventListeners;
      // eventListeners.forEach(({ event, callback }) => {
      //     console.debug('[SOCKET] - Re-listening to event:', event);
      //     this.socket?.on(event, callback);
      // });
  });

    this.socket.on('connect_error', () => {
      console.debug('[SOCKET] - Connection error');
    });
    this.socket.on("connection", (socket) => {
      if (socket.recovered) {
        // recovery was successful: socket.id, socket.rooms and socket.data were restored
        console.log(socket)
      } else {
        // new or unrecoverable session
        console.log("error recovering session ")
      }
    });

    this.socket.on('disconnect', (reason) => {
      store.dispatch(setStatus(false));
      console.debug('[SOCKET] - Disconnected');
      console.debug('[REASON] ',reason)
    });
    this.socket.onAny((event, ...args) => {
      console.debug(`[SOCKET] - Event received: ${event}`, args);
    
    });
  },

  subscribe({ topic }: { topic: string }) {
    console.debug('[SOCKET] - Activating subscription to:', topic);
    store.dispatch(addSubscription(topic));
    this.socket?.emit('subscriptions', {
      topics: [topic] 
    });
  },

  unsubscribe() {
    console.debug('[SOCKET] - Unsubscribing from all topics');
    store.dispatch(clearSubscriptions());
    this.socket?.off("message");
    this.socket?.off("connect");
    this.socket?.off("disconnect");
    
    this.socket?.emit('subscriptions', {
      topics: [ ] 
    });
  },

  closeConnection() {
    console.debug('[SOCKET] - Closing connection');
    this.socket?.close();
    this.socket = null;
  },

  listen({ event, callback }: { event: string; callback: (data: any) => void }) {
    // console.log('[SOCKET] - Listening to event:', event);
    // store.dispatch(addEventListener({ event, callback }));
    
    this.socket?.off(event);

    this.socket?.on(event, (data) => {
      store.dispatch(
        addEvent(JSON.parse(JSON.stringify(data)))
      );
      callback(data); 
    });
  },

  unlisten({ event, callback }: { event: string; callback: (data: any) => void }) {
    console.debug('[SOCKET] - Unlistening from event:', event);
    this.socket?.off(event, callback);
  },
};

export default socketManagerV2;
