import React, { useState } from "react";
import PageWrapper from "../components/PageWrapper";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { fetchChannelLocals, fetchUserkeysChannel } from "../shared/queries";
import Button from "../components/Button";
import BaseTable from "../components/BaseTable";
import Pagination from "../components/Pagination";
import FormField from "../components/FormField";
import SelectInput from "../components/SelectInput";
import { BaseSelectItem } from "../types";
import { useHistory } from "react-router-dom";
import Icon from "../components/Icon";

type Props = {};

const BookingEngine = (_props: Props) => {
  const { t } = useTranslation();
  const limitPage = 15;
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState<any[]>([]);
  const [totalData, setTotalData] = useState(0);
  const [userKey, setUserKey] = useState({ label: null, value: null });

  const { data: userKeys, isLoading: isLoadingUserKeys } = useQuery<any>(
    ["userkeys"],
    () =>
      fetchUserkeysChannel().then((data) => {
        const userKeyListTemp = data.results.map((d: any) => {
          return {
            label: d.name,
            value: d.user_key,
          };
        });
        return userKeyListTemp;
      })
  );

  const { isFetching } = useQuery<any>(
    ["channelmanager", limitPage, currentPage],
    () =>
      fetchChannelLocals(limitPage, currentPage)
        .then((data) => {
          setPageData(data.results.rooms);
          setTotalData(data.results.total_rooms);
          return data;
        })
        .catch(() => {
          setPageData([]);
          setTotalData(0);
        })
  );

  const getColumns = () => {
    return [
      {
        Header: t("channelManager.name") || "",
        accessor: (row: any) => <div className="px-4 py-2">{row.name}</div>,
      },
     /*  {
        Header: t("channelManager.status") || "",
        accessor: (row: any) => {
          if (
            row.status_room === "pending" ||
            row.status_rate === "pending" ||
            row.status_integ === "pending"
          ) {
            return (
              <div
                className={
                  "bg-yellow-100 text-yellow-800 inline-block text-xs font-medium px-2 rounded-2xl py-2"
                }
              >
                {t("channelManager.room.statusPending")}
              </div>
            );
          }
          return (
            <div
              className={
                "bg-green-100 text-green-800 inline-block text-xs font-medium px-2 rounded-2xl py-2"
              }
            >
              {t("channelManager.room.statusOk")}
            </div>
          );
        },
      }, */
      {
        Header: "Link",
        accessor: (row: any) => {
          return (
            <div className="max-w-max">
              <Button
                label={t("channelManager.reservationLink")}
                dataTip={row.status_bkengine}
                icon="calendar"
                iconSize={"20px"}
                color={row.status_bkengine === "ok" ? 'primary' : 'negative'}
                disabled={row.status_bkengine !== "ok"}
                onClick={() => {
                  window.open(`https://booking.vikey.it/?local_key=${row.local_key}`, '_blank');
                }}
              />
            </div>
          );
        },
      },
      {
        Header: t("channelManager.detail") || "",
        accessor: (row: any) => (
          <div className={"flex items-center"}>
            <div />
            <div className="max-w-max">
              <Button
                color={"info"}
                disabled={
                  row.status_room === "pending" ||
                  row.status_rate === "pending" ||
                  row.status_integ === "pending"
                }
                flat
                dataTip={(row.status_room === "pending" ||
                  row.status_rate === "pending" ||
                  row.status_integ === "pending") ? t('general.requestError') : ''}
                size="small"
                icon="arrow-narrow-right"
                iconSize="14px"
                onClick={() => {
                  history.push(`/booking-engine/detail/${row.local_key}`);
                }}
              ></Button>
            </div>
          </div>
        ),
      },
    ];
  };
  return (
    <PageWrapper>
      <>
        <div className="lg:flex lg:space-x-2 space-x-0 space-y-2 lg:space-y-0 mb-6">
          <div>
            <a
              href="https://support.vikey.it/it/articles/10469321-come-impostare-il-booking-engine"
              target={"_blank"}
              style={{
                boxShadow:
                  "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
              }}
              // className={`bg-cyan-600 flex items-center px-2 py-1 rounded-2xl text-white text-sm font-bold clic_leggiguida_channelmanager`}
              className={`bg-cyan-600 flex items-center px-2 py-1 rounded-2xl text-white text-sm font-bold clic_leggiguida_bookingengine`}
              rel="noreferrer"
            >
              {t("channelManager.readGuide")}
              <Icon name="book-open" size="16px" className="ml-1" />
            </a>
          </div>
        </div>
        <div className="bg-cyan-100 border-2 rounded-xl p-3 text-md mb-3">
          {t("bkEngine.messageInfo")}
        </div>
        {userKeys && userKeys.length > 0 && (
          <div className={"flex-1 my-8"}>
            <FormField label={t("channelManager.selectProperty")}>
              <div className="flex gap-2">
                <div className="w-full">
                  <SelectInput<BaseSelectItem, false>
                    isLoading={isLoadingUserKeys}
                    value={
                      userKeys?.find(
                        (user: any) => user.value === userKey.value
                      ) ?? { label: null, value: null }
                    }
                    options={userKeys ?? []}
                    onChange={(item) => {
                      if (item) {
                        setUserKey(item);
                      }
                    }}
                  />
                </div>
                <Button
                  icon="x"
                  padding="lg"
                  color="negative"
                  onClick={() => setUserKey({ label: null, value: null })}
                />
              </div>
            </FormField>
          </div>
        )}
        {/* EDIT PROPERTY */}
        <BaseTable
          identifierKey={"local_key"}
          data={pageData}
          className={"mb-4"}
          loading={isFetching}
          columns={getColumns()}
        />
        {pageData && pageData.length > 1 ? (
          <div className="mt-5">
            <Pagination
              page={currentPage}
              pageSize={limitPage}
              totalItems={totalData}
              onPageUpdate={(value) => {
                if (!isFetching) {
                  if (value === "+") {
                    setCurrentPage((prev) => prev + 1);
                    return;
                  }
                  if (value === "-") {
                    setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
                    return;
                  }
                  setCurrentPage(+value);
                }
              }}
            />
          </div>
        ) : null}
      </>
    </PageWrapper>
  );
};

export default BookingEngine;
